import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { global } from './global';

@Injectable({
    providedIn: 'root'
})

export class UserService {
    url: string;
    identity;
    token;
    details;
    cadianStorage;
    changeBusinessImage = new EventEmitter();

    constructor(public _http: HttpClient) {
        this.url = global.url;
    }

    businessImageChanged() {
        this.changeBusinessImage.emit();
    }

    public register(user): Observable<any> {
        let json = JSON.stringify(user);
        let params = 'json=' + json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.url + 'register', params, { headers: headers });
    }



    public login(user, gettoken = null): Observable<any> {
        if (gettoken != null) {
            user.gettoken = 'true';
        }

        let json = JSON.stringify(user);
        let params = 'json=' + json;
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this._http.post(this.url + 'login', params, { headers: headers });
    }

    public update(token, user): Observable<any> {
        let json = JSON.stringify(user);
        let params = "json=" + json;
        let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded').set('authorization', token);
        return this._http.put(this.url + 'user/update', params, { headers: headers });
    }

    public updateImageProfile(token, formData: any): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url + 'user/upload-image-profile', formData, { headers: headers });
    }

    public updateImageCedula(token, formData: any): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url + 'user/upload-image-cedula', formData, { headers: headers });
    }

    public getIdentity() {
        let identity = JSON.parse(localStorage.getItem('identity'));

        if (identity && identity != 'undefined') {
            this.identity = identity;
        } else {
            this.identity = null;
        }

        return this.identity;
    }

    public getDetails() {
        let details = JSON.parse(localStorage.getItem('details'));

        if (details && details != 'undefined') {
            this.details = details;
        } else {
            this.details = null;
        }

        return this.details;
    }

    public getToken() {
        let token = localStorage.getItem('token');

        if (token && token != 'undefined') {
            this.token = token;
        } else {
            this.token = null;
        }

        return this.token;
    }

    public recuperarPassword(form): Observable<any> {
        return this._http.post(this.url + 'forgot', form);
    }

    public checkTokenForgot(form): Observable<any> {
        return this._http.post(this.url + 'check-token-forgot', form);
    }

    public cambiarPassword(form): Observable<any> {
        return this._http.post(this.url + 'cambiar-password', form);
    }

    public enviarInvitacion(token, form): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url + 'user/invitacion', form, { headers: headers });
    }

    public updateOnline(token, form): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url + 'user/update-online', form, { headers: headers });
    }

    public isOnline(user, token): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.get(this.url + 'is-online/' + user, { headers: headers });
    }

    public getNotificaciones(token): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.get(this.url + 'user/notificaciones', { headers: headers });
    }

    public readNotification(token): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.put(this.url + 'user/read-notificaciones', null, { headers: headers });
    }

    public agregarTarjeta(token, card): Observable<any> {
        let json = JSON.stringify(card);
        let params = 'json=' + json;
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.post(this.url + 'user/agregar-tarjeta', params, { headers: headers });
    }

    public checkSession(token): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        return this._http.get(this.url + 'user/check-session', { headers: headers });
    }


}
