import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { AddressService } from '../../../services/address.service';
import { ShoppingCartService } from "../../../services/shopping-cart.service";
import { Router } from '@angular/router';
import { global } from '../../../services/global';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.css']
})
export class CarritoComponent implements OnInit {
  public shoppingCartPublications: any[] = [];
  public subtotal: any = '0.00';
  public iva: any = '0.00';
  public discount: any = '0.00';
  public total: any = '0.00';
  public urlStorage: string = '';
  public userId: any = 0;
  public addresses: any[] = [];
  public addressIdToUse: any = 0;


  constructor(
    private _userService: UserService,
    private _addressService: AddressService,
    private _shoppingCartService: ShoppingCartService,
    private _router: Router
  ) {
    this.urlStorage = global.urlStorage;
    let userIdentity = this._userService.getIdentity();

    if (userIdentity !== null) {
      this.userId = userIdentity.sub;
    }
  }
  ngOnInit(): void {
    if (this.userId !== 0) {
      this.getAddresses();
      this.getShoppingCartPublications();
    }
  }

  private getAddresses() {
    this._addressService.getAddresses(this.userId, this._userService.getToken()).subscribe(
      response => {
        if (response.error) {

        } else {
          this.addresses = response.addresses;

          if (this.addresses.length >= 1) {
            this.addressIdToUse = this.addresses[0].id_address;
            console.log(this.addressIdToUse);
          }
        }
      },
      error => {
      }
    );
  }

  private getShoppingCartPublications() {
    this._shoppingCartService.getShoppingCartPublications(this.userId, this._userService.getToken()).subscribe(
      response => {
        if (response.error) {

        } else {
          /*   console.log(response.data); */
          var data = response.data;
          this.shoppingCartPublications = data.publications;
          this.subtotal = data.subtotal;
          this.iva = data.iva;
          this.discount = data.discount;
          this.total = data.total;
        }
      },
      error => {
        console.log(error)
      }
    );
  }

  public removePublicationFromShoppingCart(keyPublication) {
    this._shoppingCartService.removePublicationFromShoppingCart(this.userId, keyPublication, this._userService.getToken()).subscribe(
      response => {
        if (response.error) {

          Swal.fire(
            '¡Ups! Algo salio mal',
            'Por favor recarga la pagina e intenta de nuevo',
            'error'
          );
        } else {
          Swal.fire(
            '¡Producto eliminado del carrito!',
            '',
            'success'
          );

          this.getShoppingCartPublications();
        }
      },
      error => {

        Swal.fire(
          '¡Ups! Algo salio mal',
          'El producto no pudo ser eliminado al carrito',
          'error'
        );
      }
    );
  }

  public buyShoppingCart() {
    if (this.shoppingCartPublications.length > 0 && this.userId !== 0) {
       if (this.addressIdToUse == 0) {
         Swal.fire(
           'No tienes dirección de envio',
           'Necesitas tener por lo menos una dirección de envio en tu cuenta. Los productos que compres se enviaran a dicha dirección.',
           'info'
         );
       } else {
         this._router.navigate(['/perfil_cliente/carrito/comprar_carrito/' + this.addressIdToUse]);
       }
     }
  }

  // Test comment...
  public getFirstImg(strImgs): string {
    /*  return JSON.parse(strImgs)[0]; */
    return strImgs[0];

  }

}
