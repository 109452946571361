import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user';
import { catchError, map } from 'rxjs/operators';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { global } from '../../../services/global';
import Swal from 'sweetalert2';
import * as UIkit from 'uikit';
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {
	private imageChangedEvent: any = '';
	private loadingImage: boolean = false;
	user: User;
	identity: any;
	token: any;
	details: any;

  uploadingImageNumber: number = 0;
	uploadingImage: boolean = false;
	cssUploadedImages: string[] = ['', '', '', '']; // Valid values = table-success | table-danger
	uploadingImageProgress: number = 0;

	@ViewChild("imageFile", {static: false})
	private imageFile: ElementRef;
	constructor(private _userService: UserService) {
		this.user = new User(1, '', '', '', '', '', '', '');
    	this.loadUser();
	}

  ngOnInit(): void {
  }

  loadUser() {
		this.identity = this._userService.getIdentity();
		this.token = this._userService.getToken();
		this.details = this._userService.getDetails();

		this.user = new User(
			this.identity.sub,
			this.details.name,
			this.details.last_name,
			this.details.description,
			this.details.email,
      '',
      '',
      ''
		);
	}

  onSubmit(form) {
		this._userService.update(this.token, this.user).subscribe(
			response => {
				if (response.estatus == 'success') {
					Swal.fire('Datos actualizados con éxito', response.message, 'success');
					this.details = response.changes;
					localStorage.setItem('details', JSON.stringify(this.details));
				}
			},
			errorResponse => {
				let errores = '';
				Object.keys(errorResponse.error).forEach(k => { errores += '- ' + errorResponse.error[k] + "<br>"; });
				console.log(errorResponse);
				Swal.fire({title: 'UPS', icon: 'error'});
			}
		);
	}

  	public changeImage(event) {
		if (event.target.files.length > 0) {
			this.imageChangedEvent = event;
			this.loadingImage = true;
			let modal = UIkit.modal(".cropper");
			modal.show();
		}
	}

	public submitImage(): void {
		// ...
	}
}
