<div class="page-title-overlap bg-dark pt-4"
    style="background-image: url('assets/images/banner-client.jpg'); background-size: 100%;">
    <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li class="breadcrumb-item">
                        <a class="text-nowrap" [routerLink]="['/']"><i class="czi-home"></i>Inicio</a>
                    </li>
                    <li class="breadcrumb-item text-nowrap active" aria-current="page">
                        <span class="czi-arrow-right text-nowrap"></span> Mi perfil
                    </li>
                </ol>
            </nav>
        </div>
        <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
            <h1 class="h3 text-light mb-0">
                <span class="czi-user-circle"></span> Mi Perfil
            </h1>
            <h1 class="h3 text-light mb-0">
                <span class="czi-user-circle"></span> Bienvenido, {{ identity.name }}
            </h1>
        </div>
    </div>
</div>

<!-- nav bar menu -->
<!-- <nav class="navbar navbar-main navbar-expand-lg border-bottom"> -->
<nav class="navbar navbar-main navbar-expand border-bottom">
    <div class="container">
        <ul class="navbar-nav">
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" href="#"> <i
                        class="fa fa-bars text-muted mr-2"></i> Menu </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <div>
                        <a [routerLink]="['/perfil_cliente']" class="dropdown-item text-primary">
                            Mi perfil
                        </a>
                    </div>
                    <div>
                        <a [routerLink]="['/perfil_cliente/carrito']" class="dropdown-item text-primary">
                            Carrito de compras
                        </a>
                    </div>
                    <div>
                        <a [routerLink]="['/perfil_cliente/deseos']" class="dropdown-item text-primary">
                            Lista de deseos
                        </a>
                    </div>

                    <div>
                        <a [routerLink]="['/perfil_cliente/direcciones']" class="dropdown-item text-primary">
                            Direcciones
                        </a>
                    </div>
                    <div>
                        <a [routerLink]="['/perfil_cliente/compras']" class="dropdown-item text-primary">
                            Compras
                        </a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</nav>
<!-- Show selected option content -->
<section class="section-content padding-y">
    <div class="container">
        <div class="row">
            <main class="col-md-12">
                <router-outlet></router-outlet>
            </main>
        </div>
    </div>
</section>