<section class="section-content padding-y mt-3">
    <div class="container">
        <div class="row">
            <main class="col-md-9">
                <div class="card border-0">
                    <table class="table table-borderless table-shopping-cart border border-secondary">
                        <thead class="border-0 bg-light">
                            <tr class="small text-uppercase border-0">
                                <th scope="col" class="border-0">Producto</th>
                                <th scope="col" class="border-0">Cantidad</th>
                                <th scope="col" class="border-0">Subtotal/Precio</th>
                                <th scope="col" class="border-0">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Show all publications of the shopping cart -->
                            <tr *ngFor="let publication of shoppingCartPublications">
                                <td>
                                    <figure class="itemside">
                                        <!-- Test comment... -->
                                        <div class="aside"><img width="150"
                                                src="{{ this.urlStorage + publication.id_seller + '/products/' + getFirstImg(publication.images) }}"
                                                class="img-sm"></div>
                                        <figcaption class="info">
                                            <a [routerLink]="['/publicacion/' + publication.id_publication]"
                                                class="title text-dark">{{ publication.title }}</a>
                                        </figcaption>
                                    </figure>
                                </td>
                                <td>
                                    <div class="input-group input-group-sm mb-3">
                                        <label class="form-control">{{ publication.selected_quantity }}</label>
                                    </div>
                                </td>
                                <td>
                                    <div class="price-wrap">
                                        <div class="price">${{ publication.subtotal }}</div>
                                        <small class="text-muted"> ${{ publication.price }} por producto</small>
                                    </div>
                                </td>
                                <td class="text-right">
                                    <a (click)="removePublicationFromShoppingCart(publication.id_publication)"
                                        class="btn btn-light"><i class="fa fa-trash"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="card-body border-0">
                        <a [routerLink]="['/']" class="btn btn-light" style="border: solid 1px lightgray; margin: 8px;">
                            <i class="fa fa-chevron-left"></i>
                            Buscar mas productos
                        </a>
                        <a (click)="buyShoppingCart()" class="btn btn-primary float-md-right"
                            style="margin: 8px; color: white;">
                            Comprar todo
                            <i class="fa fa-chevron-right"></i>
                        </a>
                    </div>
                </div>
                <div class="alert alert-success mt-3">
                    <p class="icontext"><i class="icon text-success fa fa-truck"></i> Llega gratis de 1 a 2 semanas</p>
                </div>
            </main>
            <aside class="col-md-3">
                <div class="card mb-3">
                    <div class="card-body">
                        <form>
                            <div class="form-group">
                                <label>Enviar todo a</label>
                                <div class="input-group">
                                    <select [(ngModel)]="addressIdToUse" class="form-control"
                                        [ngModelOptions]="{standalone: true}">
                                        <option value="{{item.id_address}}" *ngFor="let item of addresses">
                                            {{ item.postal_code_data.municipality_name }}, {{ item.postal_code_data.entity_name
                                            }} - {{ item.street }} {{ item.inside_number }} - CP {{
                                            item.postal_code_data.postal_code }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div>
                            <span class="font-weight-bold">Subtotal</span>
                            <div class="float-right"> $ {{ subtotal }}</div>
                        </div>
                        <div>
                            <span class="font-weight-bold">IVA</span>
                            <div class="float-right"> $ {{ iva }}</div>
                        </div>
                        <div>
                            <span class="font-weight-bold">Descuento</span>
                            <div class="float-right"> $ {{ discount }}</div>
                        </div>
                        <hr>
                        <div>
                            <span class="font-weight-bold">Total</span>
                            <div class="float-right"> $ {{ total }}</div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
    </div>
</section>