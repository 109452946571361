import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { routing, appRoutingProviders } from '../app.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedRoutingModule } from './shared-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AddonsComponent } from './components/addons/addons.component';


@NgModule({
  declarations: [HeaderComponent, FooterComponent, NavbarComponent, AddonsComponent],
  exports: [NavbarComponent, FooterComponent, AddonsComponent],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgbModule,
    FormsModule
  ]
})

export class SharedModule { }


