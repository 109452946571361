import { Component, DoCheck, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { global } from '../../services/global';
@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.css'],
  providers: [UserService]
})
export class ClientProfileComponent implements OnInit,DoCheck {
  public identity;
  public token;
  public details;
  public url;

  constructor(    public _userService: UserService,    
    private _router: Router  
  ) {    
    this.loadUser();
    this.url = global.url;
  }

  ngDoCheck(){
    this.loadUser();
  }

  loadUser() {
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }


  ngOnInit(): void {

  }

}
