export class Address {
	public id_address: number = 0;
	public key_user: number = 0;
	public key_zipcode: number = 0;
	public street: string = '';
	public phone_number: number = 0;
	public outside_number: number = 0;
	public inside_number: number = 0;
	public street_1: string = '';
	public street_2: string = '';
	public information: string = '';

	constructor() {
		// Implement this method when you need it.
	}
}