import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { routing, appRoutingProviders } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { ErrorComponent } from './components/error/error.component';
import { HomeComponent } from './components/home/home.component';
import { PublicationComponent } from './components/publication/publication.component';
import { ClientProfileComponent } from './components/client-profile/client-profile.component';
import { MyAccountComponent } from './components/client-profile/my-account/my-account.component';
import { AddressesComponent } from './components/client-profile/addresses/addresses.component';
import { BuyShoppingCartComponent } from './components/client-profile/buy-shopping-cart/buy-shopping-cart.component';
import { CarritoComponent } from './components/client-profile/carrito/carrito.component';
import { AuthenticationGuard } from "./guards/authentication.guard";
import { DeseosComponent } from './components/client-profile/deseos/deseos.component';
import { ComprasComponent } from './components/client-profile/compras/compras.component';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchComponent } from './components/search/search.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    ErrorComponent,
    HomeComponent,
    PublicationComponent,
    ClientProfileComponent,
    MyAccountComponent,
    AddressesComponent,
    BuyShoppingCartComponent,
    CarritoComponent,
    DeseosComponent,
    ComprasComponent,
    SearchComponent
  ],
  imports: [
    routing,
    SharedModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SocialLoginModule,
    NgbModule,
    NgxSpinnerModule,
    BrowserAnimationsModule
  ],
  providers: [
    appRoutingProviders,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '443742187434-m3t9tl4v4heldc9d0g2g69arddrocli2.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1141441989694613')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    AuthenticationGuard,

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // ...
}
