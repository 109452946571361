<main class="col-md-12 mt-3">
    <div class="card">
      <div class="card-body">
        <form class="row" #editAccountForm="ngForm" (ngSubmit)="onSubmit(editAccountForm)">
          <div class="col-md-9">
            <div class="form-row">
              <div class="col form-group">
                <label>Nombre</label>
                <input type="text" class="form-control" name="name" [(ngModel)]="user.name" required />
              </div>
              <div class="col form-group">
                <label>Apellido</label>
                <input type="text" class="form-control" name="surname" [(ngModel)]="user.last_name" required />
              </div>
            </div>
            <div class="form-row">
              <div class="col form-group">
                <label>Email</label>
                <input type="email" class="form-control" [disabled]="true" name="email" [(ngModel)]="user.email" required />
              </div>
              <div class="form-group col-md-6">
                <label>Acerca de mi</label>
                <textarea class="form-control" name="description" [(ngModel)]="user.description" cols="30" rows="5"
                  required></textarea>
              </div>
            </div>
            <button class="btn btn-success" [disabled]="!editAccountForm.valid" type="submit" style="border: solid 1px lightgray; margin: 8px;">
              Guardar
            </button>
            <button class="btn btn-primary" [disabled]="true" style="border: solid 1px lightgray; margin: 8px;">Cambiar contraseña</button>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
          </div>
          <div class="col-md-3">
            <div class="card-profile-image uk-transition-toggle">
              <form (ngSubmit)="submitImage()">
                <input type="file" accept="image/*" #profile name="profile" (change)="changeImage($event)" style="display: none;">
                <img src="assets/images/user.png" class="rounded-circle img-responsive" style="width:8.5rem;height:auto;object-fit: cover;object-position: left;">
                <img (click)="profile.click()" class="uk-transition-scale-up uk-position-cover " style="border-radius: 50%;width: 30%;height: 10%;margin-top: 6px;cursor: pointer;" src="assets/images/change.png" alt="">
              </form>
            </div>
          </div>
          <div class="col-lg-5 order-lg-2"></div>
        </form>
      </div>
    </div>

  </main>
  