<div class="container">
	<!-- ========================= SECTION MAIN  ========================= -->
	<section class="section-main padding-y">
	<main class="card">
		<div class="card-body">
			<div class="row">
				<!-- <aside class="col-lg col-md-3 flex-lg-grow-0">
					<nav class="nav-home-aside">
						<h6 class="title-category">CATEGORIAS<i class="d-md-none icon fa fa-chevron-down"></i></h6>
						<ul class="menu-category">
							<li><a href="#">Sheer Elegance</a></li>
							<li><a href="#">Enrollable</a></li>
							<li><a href="#">Panel Deslizante</a></li>				
						</ul>
					</nav>
				</aside> -->
			<div class="col-12">
				<!-- ================== COMPONENT SLIDER  BOOTSTRAP  ==================  -->
				<div  id="carousel1_indicator" class="slider-home-banner carousel slide" data-ride="carousel">
				
				  <div  class="carousel-inner">
				    <div class="carousel-item active">
				      <img src="assets/images/slides/slide04.jpg" alt="Primer slide"> 
				    </div>
				    <div class="carousel-item">
				      <img src="assets/images/slides/slide05.jpg" alt="Segundo slide">
				    </div>
				    

				  </div>
				  <ol  style=" position: absolute; z-index: 0;  "  class="carousel-indicators">
				    <li data-target="#carousel1_indicator" data-slide-to="0" class="active"></li>
				    <li data-target="#carousel1_indicator" data-slide-to="1"></li>
				    <li data-target="#carousel1_indicator" data-slide-to="2"></li>
				  </ol>
				  <a style=" position: absolute; z-index: 0;  " class="carousel-control-prev" href="#carousel1_indicator" role="button" data-slide="prev">
				    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
				    <span class="sr-only">Previous</span>
				  </a>
				  <a  style=" position: absolute; z-index: 0;  "  class="carousel-control-next" href="#carousel1_indicator" role="button" data-slide="next">
				    <span class="carousel-control-next-icon" aria-hidden="true"></span>
				    <span class="sr-only">Next</span>
				  </a>
				</div> 
			</div> 
		</div> 
	</div> 
	</main> <!-- card.// -->

	<section  class="padding-bottom">
		<app-addons></app-addons>
		<div class="row">
			<div class="col-md-3 col-sm-6 mb-4">
				<article class="card">
				  <img src="assets/images/persishops web-07.jpg" class="card-img-top" style="object-fit: scale-down;">
				</article> <!-- card.// -->
			</div> <!-- col.// -->
			<div class="col-md-3 col-sm-6 mb-4">
				<article class="card">
				  <img src="assets/images/persishops web-08.jpg" class="card-img-top" style="object-fit: scale-down;">
				</article> <!-- card.// -->
			</div> <!-- col.// -->
			<div class="col-md-3 col-sm-6 mb-4">
				<article class="card">
				  <img src="assets/images/persishops web-09.jpg" class="card-img-top" style="object-fit: scale-down;">
				</article> <!-- card.// -->
			</div> <!-- col.// -->
			<div class="col-md-3 col-sm-6 mb-4">
				<article class="card">
				  <img src="assets/images/entrega.jpg" class="card-img-top" style="object-fit: scale-down;">
				</article> <!-- card.// -->
			</div> <!-- col.// -->
		</div> <!-- row.// -->
</section>