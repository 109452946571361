<div style="margin-top: 80px;"></div>

<!-- Showing publication -->
<section class="section-content padding-y">
	<div class="container">
		<div class="row">
			<aside class="col-md-7">
				<div class="card mb-3">
					<article class="gallery-wrap">
						<div class="row">
							<div class="img-big-wrap big-img-container">
			  					<a>
			  						<img *ngIf="publication.id_publication != 0" class="big-img" src="{{ this.urlStorage + publication.product.key_user + '/products/' + publication.product.images }}">
			  					</a>
							</div>
						</div>
						<!-- <div class="thumbs-wrap">
						  <a href="#" class="item-thumb"> <img src="{{ this.url + 'get-publication-image/' + publication.key_user + '/' + publication.product.images }}"></a>
						  <a href="#" class="item-thumb"> <img src="images/items/15-1.jpg"></a>
						  <a href="#" class="item-thumb"> <img src="images/items/15-2.jpg"></a>
						  <a href="#" class="item-thumb"> <img src="images/items/15-1.jpg"></a>
						</div> -->
					</article>
				</div>
			</aside>
			<main class="col-md-5">
				<article class="product-info-aside">
					<h2 class="title mt-3">{{ publication.title }}</h2>
					<div class="rating-wrap my-3">
                        <i class="fa fa-star" style="color: #EEE000;"></i>
                        <i class="fa fa-star" style="color: #EEE000;"></i>
                        <i class="fa fa-star" style="color: #EEE000;"></i>
                        <i class="fa fa-star" style="color: #EEE000;"></i>
                        <i class="fa fa-star" style="color: lightgray;"></i>
                        <div>
                        	<small class="label-rating text-muted">132 reseñas</small>
							<small class="label-rating text-success"> <i class="fa fa-clipboard-check"></i> 154 vendidos </small>
                        </div>
                    </div>
					<div class="mb-3"> 
						<var class="price h4">MXN ${{ publication.product_price }}</var> 
						<span class="text-muted"> IVA no incluido</span> 
					</div>
					<!-- <p>{{ publication.product.description }}</p> -->
					<!-- <dl class="row">
					  <dt class="col-sm-3">Vendedor</dt>					  
					  <dd *ngIf="publication.user.key_type_user == 1" class="col-sm-9"><a [routerLink]="['/vendedor/' + publication.user.email_hash]"> {{ publication.user.name }} </a></dd>
					  <dd *ngIf="publication.user.key_type_user == 2" class="col-sm-9"><a [routerLink]="['/tienda/' + publication.user.email_hash]"> {{ publication.user.name }} </a></dd>
					</dl> -->
					<div class="form-row  mt-4">
						<!-- <div class="form-group col-md flex-grow-0">
							<div class="input-group mb-3 input-spinner">
							  <div class="input-group-prepend">
							    <button class="btn btn-light" type="button" id="button-plus"> + </button>
							  </div>
							  <input type="text" class="form-control" value="1">
							  <div class="input-group-append">
							    <button class="btn btn-light" type="button" id="button-minus"> &minus; </button>
							  </div>
							</div>
						</div> -->
						<div class="form-group col-md">
							<div class="form-group col-md">
								<button (click)="addToShoppingCartAndGoToBuyIt(publication.id_publication)" class="btn btn-success m-2" style="color: white;" [disabled]="publication.key_publication_status != 1">
									<i class="fa fa-bolt"></i>
									<span class="text"> Comprar</span>
								</button>
								<a (click)="updateFavoritesList(publication.id_publication)" *ngIf="!publicationInFavorites" class="btn btn-primary m-2" title="Agregar a tu lista de favoritos" style="border: solid 1px lightgray;">
					        		<i style="color: white;" class="fa fa-heart"></i>
								</a>
								<a (click)="updateFavoritesList(publication.id_publication)" *ngIf="publicationInFavorites" class="btn btn-primary m-2" title="Eliminar de tu lista de favoritos" style="border: solid 1px lightgray;">
					        		<i style="color: #F00;" class="fa fa-heart"></i>
								</a>
								<button (click)="addPublicationToShoppingCart(publication.id_publication)" class="btn btn-primary m-2" style="color: white;" [disabled]="publication.key_publication_status != 1">
									<i class="fa fa-shopping-cart"></i>
									<span class="text"> Agregar al carrito</span>
								</button>
							</div>
						</div>
					</div>
				</article>
			</main>
		</div>
	</div>
</section>
<section class="section-name padding-y mb-3">
	<div class="container">
		<div class="row">
			<div class="col-md-12">	
				<div class="card custom-shadows-p0">
  					<div class="card-header custom-card-header-bg">
  						<h5 class="title-description">Descripción</h5>
  					</div>
  					<div style="white-space: pre-line;" class="card-body">
  						<p>{{ publication.description }}</p>
  					</div>
				</div>
			</div>			
		</div>
	</div>
</section>