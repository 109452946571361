<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Compra ID: {{ currentPurchaseId }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <!-- product start -->
                <div *ngFor="let item of purchasesDetail; index as i; last as isLast" class="card p-0 mb-4">
                    <div class="card-header">Producto #{{ i + 1 }}/{{ purchasesDetail.length }}: {{ item.product_name }}
                    </div>
                    <div class="card-body">
                        <div class="d-flex justify-content-center">
                            <!-- Test comment... -->
                            <img class="img-thumbnail custom-img"
                                src="{{ this.urlStorage + item.key_seller + '/products/' + item.images[0] }}"
                                alt="Card image cap">
                        </div>
                        <table class="table">
                            <tr>
                                <td class="border-top-0">
                                    <span class="text-muted">Vendedor</span>
                                </td>
                                <td class="border-top-0">
                                    <span>{{ item.seller_name }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-muted">Precio del producto</span>
                                </td>
                                <td>
                                    <span>$ {{ item.product_price }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="text-muted">Cantidad</span>
                                </td>
                                <td>
                                    <span>{{ item.quantity }}</span>
                                </td>
                            </tr>
                        </table>
                        <div class="p-2">
                            <div class="bg-gray p-2">
                                <span class="font-weight-bold">Subtotal</span>
                                <div class="float-right"> $ {{ item.subtotal }}</div>
                            </div>
                            <div class="bg-lightgray p-2">
                                <span class="font-weight-bold">IVA</span>
                                <div class="float-right"> $ {{ item.iva }}</div>
                            </div>
                            <div class="bg-gray p-2">
                                <span class="font-weight-bold">Descuento</span>
                                <div class="float-right"> $ {{ item.discount }}</div>
                            </div>
                            <div class="bg-lightgray p-2">
                                <span class="font-weight-bold">Total</span>
                                <div class="float-right"> $ {{ item.total }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- product end -->

            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>

<main class="col-md-12 pt-4">
    <article *ngFor="let purchase of purchases" class="card mb-4">
        <div class="card-header  custom-card-header-bg">
            <div class="container">
                <div class="row">
                    <strong class="col-12 col-md-6">Compra ID: {{ getFormattedPurchaseId(purchase.id_purchase)
                        }}</strong>
                    <span class="col-12 col-md-6">{{ purchase.created_at }}</span>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 mb-4">
                        <div class="container">
                            <div class="row">
                                <span class="text-muted col-12 col-lg-3 col-xl-2"><strong>Recibe</strong></span>
                                <span class="col-12 col-lg-9 col-xl-10">{{ purchase.customer_name }}</span>
                            </div>
                            <div class="row">
                                <span class="text-muted col-12 col-lg-3 col-xl-2"><strong>Teléfono</strong></span>
                                <span class="col-12 col-lg-9 col-xl-10">{{ purchase.customer_phone }}</span>
                            </div>
                            <div class="row">
                                <span class="text-muted col-12 col-lg-3 col-xl-2"><strong>Correo</strong></span>
                                <span class="col-12 col-lg-9 col-xl-10">{{ purchase.customer_email }}</span>
                            </div>
                            <div class="row">
                                <span class="text-muted col-12 col-lg-3 col-xl-2"><strong>Dirección</strong></span>
                                <span class="col-12 col-lg-9 col-xl-10">{{ purchase.customer_address }}</span>
                            </div>
                            <div class="row">
                                <span class="text-muted col-12 col-lg-3 col-xl-2"><strong>CP</strong></span>
                                <span class="col-12 col-lg-9 col-xl-10">{{ purchase.customer_zipcode }}</span>
                            </div>
                            <div class="row">
                                <span class="text-muted col-12 col-lg-3 col-xl-2"><strong>Productos</strong></span>
                                <span class="col-12 col-lg-9 col-xl-10">{{ purchase.products_name }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 bg-light p-2">
                        <div>
                            <span class="font-weight-bold">Subtotal</span>
                            <div class="float-right"> $ {{ purchase.subtotal }}</div>
                        </div>
                        <div>
                            <span class="font-weight-bold">IVA</span>
                            <div class="float-right"> $ {{ purchase.iva }}</div>
                        </div>
                        <div>
                            <span class="font-weight-bold">Descuento</span>
                            <div class="float-right"> $ {{ purchase.discount }}</div>
                        </div>
                        <hr>
                        <div>
                            <span class="font-weight-bold">Total</span>
                            <div class="float-right"> $ {{ purchase.total }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Product delivery tracking view (start) -->
            <div id="{{ 'trackingArea' + purchase.id_purchase }}" class="collapse mt-3 pt-3 border-top">
                <div class="container-fluid">
                    <div class="row mt-3">
                        <h5 class="col-12 text-center">Detalles del envío</h5>
                    </div>
                    <div *ngFor="let dtItem of purchase.delivery_tracking_items; index as i;" class="row">
                        <div class="col-2" style="min-height: 80px;">
                            <div class="{{ dtItem.complementTagCssClass }}" [class.custom-i-bottom-complement]="i == 0"
                                [class.custom-i-top-complement]="i == (purchase.delivery_tracking_items.length - 1)"
                                [class.custom-i-complement]="i != 0 && i != (purchase.delivery_tracking_items.length - 1)">
                            </div>
                            <i class="fa {{ dtItem.iconTagCssClass }} custom-i" aria-hidden="true"></i>
                        </div>
                        <div class="col-10 pt-3 pb-3"
                            [class.border-bottom]="i != (purchase.delivery_tracking_items.length - 1)"
                            style="min-height: 80px;">
                            <p>{{ dtItem.name }}</p>
                            <p class="text-muted">{{ dtItem.date }}</p>
                            <a *ngIf="dtItem.description != '-'" class="btn btn-link" data-toggle="collapse"
                                href="{{ '#status7InfoArea' + purchase.id_purchase + i.toString() }}" role="button"
                                aria-expanded="false">Información</a>
                            <div *ngIf="dtItem.description != '-'"
                                id="{{ 'status7InfoArea' + purchase.id_purchase + i.toString() }}"
                                class="collapse mt-3 row">
                                <div class="col-12 col-lg-8" style="display: inline-block;">
                                    <p class="font-italic text-center" style="display: inline-block; width: 100%;">- "{{
                                        dtItem.description }}" -</p>
                                </div>
                                <div class="col-12 col-lg-4 text-center" style="display: inline-block;">
                                    <img class="img-thumbnail custom-img3"
                                        src="{{ urlStorage + dtItem.deliveryManId + '/deliveries/failed/' + dtItem.images[0] }}">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <button (click)="getPurchaseById(purchase.id_purchase)" class="btn"
                                [class.btn-primary]="refreshingPurchaseId != purchase.id_purchase"
                                [class.btn-secondary]="refreshingPurchaseId == purchase.id_purchase"
                                [disabled]="refreshingPurchaseId == purchase.id_purchase">
                                <i class="fa fa-refresh"
                                    [class.fa-spin]="refreshingPurchaseId == purchase.id_purchase"></i>
                                Actualizar
                            </button>
                        </div>
                    </div>

                    <!-- Delivery man data -->
                    <div class="row mt-4">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5>Repartidor</h5>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 text-center mb-3">
                                            <img src="assets/images/user.png" class="custom-img2">
                                        </div>
                                        <p class="col-12 text-center">{{ purchase.delivery_man_name }}</p>
                                        <p class="col-12 text-center">{{ purchase.delivery_man_telephone }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Product delivery tracking view (end) -->

        </div>
        <div class="card-footer">
            <div class="float-right">
                <!-- <button class="btn btn-outline-primary mr-2"><i class="fa fa-print"></i> Imprimir</button> -->
                <a class="btn btn-outline-primary m-1" data-toggle="collapse"
                    href="{{ '#trackingArea' + purchase.id_purchase }}" role="button" aria-expanded="false">
                    <i class="fa fa-location-arrow" aria-hidden="true"></i>
                    Envio
                </a>
                <button (click)="fillModalWindow(purchase.id_purchase)" class="btn btn-outline-primary m-1"
                    data-toggle="modal" data-target=".bd-example-modal-lg">
                    <i class="fa fa-info-circle"></i>
                    Detalle
                </button>
            </div>
        </div>
    </article>
</main>