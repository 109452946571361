import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShoppingCartService } from '../../../services/shopping-cart.service';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

declare let OpenPay: any;

@Component({
  selector: 'app-buy-shopping-cart',
  templateUrl: './buy-shopping-cart.component.html',
  styleUrls: ['./buy-shopping-cart.component.css']
})
export class BuyShoppingCartComponent implements OnInit {
  private deviceSessionId: string = '';
	private tokenId: string = '';
	private OpenPay: any;
	private userId: number = 0;
	private userToken: any;
	private addressIdToUse: any = '';

	cardData: any = {
		holderName: "",
		number: "",
		expirationMonth: "",
		expirationYear: "",
		cvv2: ""
	};

	holderName: string = '';

	constructor(
		private _shoppingCartService: ShoppingCartService,
		private _userService: UserService,
		private _route: ActivatedRoute,
		private _router: Router,
		private spinnerService: NgxSpinnerService
	) {
		let userIdentity = this._userService.getIdentity();

		if (userIdentity !== null) {
			this.userId = userIdentity.sub;
			this.userToken = this._userService.getToken();
		}
	}

	click() {
		console.log(this.cardData);
	}

	ngOnInit(): void {
		this._route.params.subscribe(
			params => {
				this.addressIdToUse = params.key_custommer_address;
			}
		);
	}

	confirmNewPurchase() {
		Swal.fire({
			icon: 'info',
			title: 'Se realizara la compra ¿desea continuar?',
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'Cancelar'
		}).then(result => {
			if (result.isConfirmed) {
				this.newPurchaseStep1();
			}
		});
	}

	newPurchaseStep1() {
		this.spinnerService.show();
		OpenPay.setSandboxMode(true);
		OpenPay.setId('mqc6gnt4direyvawlxqn');
		OpenPay.setApiKey('pk_9c0f77d7d71049c594857a571bd1b878');
		this.deviceSessionId = OpenPay.deviceData.setup();

		OpenPay.token.create(
			{
				"card_number": this.cardData.number,
				"holder_name": this.cardData.holderName,
				"expiration_year": this.cardData.expirationYear,
				"expiration_month": this.cardData.expirationMonth,
				"cvv2": this.cardData.cvv2
			},
			response => {
				this.tokenId = response.data.id;
				this.newPurchaseStep2();
			},
			error => {
				Swal.fire(
					'¡Ups! Algo salio mal',
					'La compra no pudo ser realizada',
					'error'
				);

				console.log(error);
			}
		);
	}

	private newPurchaseStep2() {
		let jsonData = {
            key_customer: this.userId,
            key_customer_address: this.addressIdToUse,
            token_id: this.tokenId,
            device_session_id: this.deviceSessionId
        };

		this._shoppingCartService.buyShoppingCart(jsonData, this.userToken).subscribe(
			response => {
				this.spinnerService.hide();
				if (response.error) {
					/* Swal.fire(
						'¡Ups! Algo salio mal',
						'La compra no pudo ser realizada',
						'error'
					); */
					Swal.fire(
						'ERROR',
						'INFO-TO-PROGRAMMER:<br><br>' +
						'error: ' + response.error + '<br>' +
						'error_type: ' + response.error_type + '<br>' +
						'status_code: ' + response.status_code + '<br>' +
						'message: ' + response.message + '<br>' +
						'error_detail_category: ' + response.error_detail.category + '<br>' +
						'error_detail_code: ' + response.error_detail.code + '<br>' +
						'error_detail_message: ' + response.error_detail.message + '<br>' +
						'error_detail_http_code: ' + response.error_detail.http_code + '<br>' +
						'error_detail_request_id: ' + response.error_detail.request_id + '<br>',
						'error'
					);
				} else {
					this.spinnerService.hide();
					Swal.fire(
						'¡Compra realizada exitosamente!',
						'',
						'success'
					);

					this._router.navigate(['/perfil_cliente/compras']);
				}
			},
			error => {
				/* Swal.fire(
					'¡Ups! Algo salio mal',
					'La compra no pudo ser realizada',
					'error'
				); */
                this.spinnerService.hide();
				Swal.fire(
					'ERROR',
					'INFO-TO-PROGRAMMER:<br><br>' +
					'error: ' + error.error.error + '<br>' +
					'error_type: ' + error.error.error_type + '<br>' +
					'status_code: ' + error.error.status_code + '<br>' +
					'message: ' + error.error.message + '<br>' +
					'error_detail_category: ' + error.error.error_detail.category + '<br>' +
					'error_detail_code: ' + error.error.error_detail.code + '<br>' +
					'error_detail_message: ' + error.error.error_detail.message + '<br>' +
					'error_detail_http_code: ' + error.error.error_detail.http_code + '<br>' +
					'error_detail_request_id: ' + error.error.error_detail.request_id + '<br>',
					'error'
				);
			}
		);
	}

}
