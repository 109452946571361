import { Component, OnInit } from '@angular/core';

import { UserService } from '../../../services/user.service';
import { PurchaseService } from '../../../services/purchase.service';
import { global } from '../../../services/global';
@Component({
  selector: 'app-compras',
  templateUrl: './compras.component.html',
  styleUrls: ['./compras.component.css']
})
export class ComprasComponent implements OnInit {
  public purchases = [];
  public purchasesDetail = [];
  private userId = 0;
  public urlStorage = '';
  public currentPurchaseId = '';
  public refreshingPurchaseId = 0;
  constructor(
    private userService: UserService,
    private purchaseService: PurchaseService
  ) {
    this.urlStorage = global.urlStorage;
    let userIdentity = this.userService.getIdentity();

    if (userIdentity !== null) {
      this.userId = userIdentity.sub;
    }
  }


  public ngOnInit(): void {
    if (this.userId !== 0) {
      this.getPurchases();
    }
  }

  public getPurchases(): void {
    this.purchaseService.getPurchases(this.userId, this.userService.getToken()).subscribe(
      (response: any) => {
        console.log(response.purchases.data);
        this.purchases = this.setDeliveryTrackingByPurchase(response.purchases.data);

      },
      (error: any) => {
        //...
        console.log(error);
      }
    );
  }

  private setDeliveryTrackingByPurchase(receivedPurchases: any[]): any[] {
    let item;
    let status;
    let showStatus7;
    let statusInprocess;

    // i = Position of purchase
    // ii = Position of delivery tracking of purchase
    // iii = Position of delivery tracking item of purchase

    for (let i = 0; i < receivedPurchases.length; i++) {
      receivedPurchases[i].delivery_tracking_items = [];

      for (let ii = 0; ii < receivedPurchases[i].delivery_trackings.length; ii++) {
        showStatus7 = false;

        for (let iii = 0; iii < receivedPurchases[i].delivery_trackings[ii].length; iii++) {
          if (receivedPurchases[i].delivery_trackings[ii][iii].key_deliveries_tracking_status == 7) {
            if (receivedPurchases[i].delivery_trackings[ii][iii].done == 1) {
              showStatus7 = true;
            }

            break;
          }
        }

        for (let iii = 0; iii < receivedPurchases[i].delivery_trackings[ii].length; iii++) {
          status = receivedPurchases[i].delivery_trackings[ii][iii].key_deliveries_tracking_status;
          statusInprocess = false;

          if (receivedPurchases[i].delivery_trackings.length != 1) {
            if (status == 3 && ii != 0) {
              continue;
            }
          }

          if (status == 6 && showStatus7) {
            continue;
          } else if ((status == 7 || status == 8) && !showStatus7) {
            continue;
          } else if (status == 9 && receivedPurchases[i].delivery_trackings[ii][iii].done != 1) {
            continue;
          }

          item = {
            name: receivedPurchases[i].delivery_trackings[ii][iii].name,
            date: receivedPurchases[i].delivery_trackings[ii][iii].date,
            complementTagCssClass: '',
            iconTagCssClass: '',
            description: receivedPurchases[i].delivery_trackings[ii][iii].description,
            images: receivedPurchases[i].delivery_trackings[ii][iii].images,
            deliveryManId: receivedPurchases[i].delivery_trackings[ii][iii].key_delivery_man
          };

          if (status == 1) {
            item.iconTagCssClass = 'fa-clock-o';
          } else if (status == 2) {
            item.iconTagCssClass = 'fa-list-alt';

            if (receivedPurchases[i].delivery_trackings[ii][iii].in_process == 1) {
              statusInprocess = true;
            }
          } else if (status == 3) {
            item.iconTagCssClass = 'fa-archive';

            if (receivedPurchases[i].delivery_trackings[ii][iii].in_process == 1) {
              statusInprocess = true;
            }
          } else if (status == 4) {
            item.iconTagCssClass = 'fa-truck';

            if (receivedPurchases[i].delivery_trackings[ii][iii].in_process == 1) {
              statusInprocess = true;
            }
          } else if (status == 5) {
            item.iconTagCssClass = 'fa-home';

            if (receivedPurchases[i].delivery_trackings[ii][iii].in_process == 1) {
              statusInprocess = true;
            }
          } else if (status == 6) {
            item.iconTagCssClass = 'fa-check-square-o';
          } else if (status == 7) {
            item.iconTagCssClass = 'fa-exclamation-triangle';
          } else if (status == 8) {
            item.iconTagCssClass = 'fa-share';
          } else if (status == 9) {
            item.iconTagCssClass = 'fa-cube';
          }

          if (receivedPurchases[i].delivery_trackings[ii][iii].done == 1 || statusInprocess) {
            if (status == 7) {
              item.complementTagCssClass += 'bg-warning';
              item.iconTagCssClass += ' bg-warning';
            } else {
              item.complementTagCssClass += 'bg-success';
              item.iconTagCssClass += ' bg-success';
            }
          }

          receivedPurchases[i].delivery_tracking_items.push(item);
        }
      }
    }

    return receivedPurchases;
  }

  public getPurchaseById(idPurchase: number): void {
    this.refreshingPurchaseId = idPurchase;
    /* 
        this.purchaseService.getPurchaseById(this.userId, idPurchase).subscribe(
          (response: any) => {
            this.setDeliveryTrackingToPurchase(response.delivery_trackings, idPurchase);
    
            for (let i = 0; i < this.purchases.length; i++) {
              if (this.purchases[i].id_purchase == idPurchase) {
                this.purchases[i].delivery_man_name = response.delivery_man_name;
                this.purchases[i].delivery_man_telephone = response.delivery_man_telephone;
                break;
              }
            }
    
            setTimeout(() => { this.refreshingPurchaseId = 0; }, 1000);
          },
          (error: any) => {
            setTimeout(() => { this.refreshingPurchaseId = 0; }, 1000);
          }
        ); */
  }

  private setDeliveryTrackingToPurchase(deliveryTrackings: any[], idPurchase: number): void {
    let item;
    let status;
    let showStatus7;
    let statusInprocess;
    let deliveryTrackingItems = [];

    // ii = Position of delivery tracking of purchase
    // iii = Position of delivery tracking item of purchase

    for (let ii = 0; ii < deliveryTrackings.length; ii++) {
      showStatus7 = false;

      for (let iii = 0; iii < deliveryTrackings[ii].length; iii++) {
        if (deliveryTrackings[ii][iii].key_deliveries_tracking_status == 7) {
          if (deliveryTrackings[ii][iii].done == 1) {
            showStatus7 = true;
          }

          break;
        }
      }

      for (let iii = 0; iii < deliveryTrackings[ii].length; iii++) {
        status = deliveryTrackings[ii][iii].key_deliveries_tracking_status;
        statusInprocess = false;

        if (deliveryTrackings.length != 1) {
          if (status == 3 && ii != 0) {
            continue;
          }
        }

        if (status == 6 && showStatus7) {
          continue;
        } else if ((status == 7 || status == 8) && !showStatus7) {
          continue;
        } else if (status == 9 && deliveryTrackings[ii][iii].done != 1) {
          continue;
        }

        item = {
          name: deliveryTrackings[ii][iii].name,
          date: deliveryTrackings[ii][iii].date,
          complementTagCssClass: '',
          iconTagCssClass: '',
          description: deliveryTrackings[ii][iii].description,
          images: deliveryTrackings[ii][iii].images,
          deliveryManId: deliveryTrackings[ii][iii].key_delivery_man
        };

        if (status == 1) {
          item.iconTagCssClass = 'fa-clock-o';
        } else if (status == 2) {
          item.iconTagCssClass = 'fa-list-alt';

          if (deliveryTrackings[ii][iii].in_process == 1) {
            statusInprocess = true;
          }
        } else if (status == 3) {
          item.iconTagCssClass = 'fa-archive';

          if (deliveryTrackings[ii][iii].in_process == 1) {
            statusInprocess = true;
          }
        } else if (status == 4) {
          item.iconTagCssClass = 'fa-truck';

          if (deliveryTrackings[ii][iii].in_process == 1) {
            statusInprocess = true;
          }
        } else if (status == 5) {
          item.iconTagCssClass = 'fa-home';

          if (deliveryTrackings[ii][iii].in_process == 1) {
            statusInprocess = true;
          }
        } else if (status == 6) {
          item.iconTagCssClass = 'fa-check-square-o';
        } else if (status == 7) {
          item.iconTagCssClass = 'fa-exclamation-triangle';
        } else if (status == 8) {
          item.iconTagCssClass = 'fa-share';
        } else if (status == 9) {
          item.iconTagCssClass = 'fa-cube';
        }

        if (deliveryTrackings[ii][iii].done == 1 || statusInprocess) {
          if (status == 7) {
            item.complementTagCssClass += 'bg-warning';
            item.iconTagCssClass += ' bg-warning';
          } else {
            item.complementTagCssClass += 'bg-success';
            item.iconTagCssClass += ' bg-success';
          }
        }

        deliveryTrackingItems.push(item);
      }
    }

    for (let i = 0; i < this.purchases.length; i++) {
      if (this.purchases[i].id_purchase == idPurchase) {
        this.purchases[i].delivery_tracking_items = deliveryTrackingItems;
        break;
      }
    }
  }

  public fillModalWindow(idPurchase: any): void {
    this.purchasesDetail = [];
    this.currentPurchaseId = '';

    for (let item of this.purchases) {
      if (item.id_purchase == idPurchase) {
        this.purchasesDetail = item.products_detail;
        this.currentPurchaseId = this.getFormattedPurchaseId(item.id_purchase);
        break;
      }
    }
  }

  public getFormattedPurchaseId(idPurchase: any): string {
    let stringId = idPurchase.toString();

    if (stringId.length < 10) {
      let formattedId = '';

      for (let index = 0; index < (10 - stringId.length); index++) {
        formattedId += '0';
      }

      stringId = formattedId + stringId;
    }

    return stringId;
  }

  // Test comment...
  public getFirstImg(strImgs: string): string {
    return JSON.parse(strImgs)[0];
  }
}
