import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { AddressService } from '../../../services/address.service';
import { User } from '../../../models/user';
import { Address } from '../../../models/address';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.css']
})

export class AddressesComponent implements OnInit {
	private userId: number = 0;
	private userToken: any;
	public addresses: any[] = [];
	public zipcodes: any[] = [];
	public zipcodeData: any;
	private savedAddress: boolean = false;
	public addressIdToDelete: number = 0;

	constructor(private _userService: UserService, private _addressService: AddressService) {
		let userIdentity = this._userService.getIdentity();

		if (userIdentity !== null) {
			this.userId = userIdentity.sub;
			this.userToken = this._userService.getToken();
		}

		// Used to save the data of the new address catched in the modal window.
		// Used with [ngModel].
		this.zipcodeData = {
			"id_zipcode": "",
			"zipcode": "",
			"entity_name": "",
			"township_name": "",
			"street": "",
			"phone_number": "",
			"outside_number": "",
			"inside_number": "",
			"street_1": "",
			"street_2": "",
			"information": ""
		};
	}

 ngOnInit(): void {
		if (this.userId !== 0) {
			this.getAddresses();
		}
	}

	public newAddressButton() {
		if (this.savedAddress) {
			this.zipcodeData = {
				"id_zipcode": "",
				"zipcode": "",
				"entity_name": "",
				"township_name": "",
				"street": "",
				"phone_number": "",
				"outside_number": "",
				"inside_number": "",
				"street_1": "",
				"street_2": "",
				"information": ""
			};
		}
	}

	private getAddresses() {
		this._addressService.getAddresses(this.userId, this.userToken).subscribe(
			response => {
				if (response.error) {
					// console.log('BYPROGRAMMER', response);
				} else {
					console.log(response.addresses);
					this.addresses = response.addresses;
				}
			},
			error => {
				// console.log('BYPROGRAMMER', error);
			}
		);
	}

	// NOTE:
	// Develop and implement the following method to validate the new address data
	// before send it to the backend.
	private validateNewAddressData(): boolean {
		return true;
	}

	public saveAddress() {
		// console.log('BYPROGRAMMER', this.zipcodeData);
		this.savedAddress = false;
		let newAddress = new Address();
		newAddress.id_address = 0;
		newAddress.key_user = this.userId;
		newAddress.key_zipcode = this.zipcodeData.id_postal_code;
		newAddress.street = this.zipcodeData.street;
		newAddress.phone_number = this.zipcodeData.phone_number;
		newAddress.outside_number = this.zipcodeData.outside_number;
		newAddress.inside_number = this.zipcodeData.inside_number;
		newAddress.street_1 = this.zipcodeData.street_1;
		newAddress.street_2 = this.zipcodeData.street_2;
		newAddress.information = this.zipcodeData.information;


		if (newAddress.street == ''	) {
			Swal.fire({
				icon: 'error',
				title: 'Campo vacio',
				text: 'Falta llenar campo de Calle'
			});

		} else if (newAddress.phone_number == 0) {
			Swal.fire({
				icon: 'error',
				title: 'Campo vacio',
				text: 'Falta llenar campo de numero de contacto'
			});
		} else if (newAddress.outside_number == 0) {

			Swal.fire({
				icon: 'error',
				title: 'Campo vacio',
				text: 'Falta llenar el campo de Numero exterior'
			});
		} else if (newAddress.street_1 == '') {

			Swal.fire({
				icon: 'error',
				title: 'Campo vacio',
				text: 'Falta llenar el campo de Calle 1'
			});

		} else if (newAddress.street_2 == '') {

			Swal.fire({
				icon: 'error',
				title: 'Campo vacio ',
				text: 'Falta llenar el campo de Calle 2'
			});

		} 

		this._addressService.newAddress(newAddress, this.userToken).subscribe(
			response => {
				if (response.error) {
					// console.log('BYPROGRAMMER', response);
					Swal.fire({
						icon: 'error',
						title: '¡UPS! Algo salio mal',
						text: 'La dirección no se pudo guardar, por favor revisa los datos que introdujiste'
					});
				} else {
					Swal.fire({
						icon: 'success',
						title: 'Dirección guardada',
						text: ''
					});
					this.savedAddress = true;
					$("#nuevaDireccion").modal("hide");
					this.getAddresses();
				}
			},
			error => {
				// console.log('BYPROGRAMMER', error);
				Swal.fire({
					icon: 'error',
					title: '¡UPS! Algo salio mal',
					text: ''
				});
			}
		);
	}


	public deleteAddress(addressId) {
		this.addressIdToDelete = addressId;
		// console.log('BYPROGRAMMER', this.userId);
		this._addressService.deleteAddress(this.userId, this.addressIdToDelete, this.userToken).subscribe(
			response => {
				if (response.error) {
					// console.log('BYPROGRAMMER', response);
					Swal.fire({
						icon: 'error',
						title: '¡UPS! Algo salio mal',
						text: 'La dirección no pudo ser eliminada'
					});
				} else {
					Swal.fire({
						icon: 'success',
						title: 'Dirección eliminada',
						text: ''
					});
					this.getAddresses();
				}
			},
			error => {
				// console.log('BYPROGRAMMER', error);
				Swal.fire({
					icon: 'error',
					title: '¡UPS! Algo salio mal',
					text: ''
				});
			}
		);
	}

	public confirmDeleteAddress(idAddress): void {
		Swal.fire({
			icon: 'info',
			title: '¿Desea eliminar la dirección?',
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No',
		}).then(result => {
			if (result.isConfirmed) {
				this.deleteAddress(idAddress);
			}
		});
	}
	
	public setMainAddress(idAddress) {
		this._addressService.setMainAddress(idAddress, this.userId, this.userToken).subscribe(
			response => {
				if (response.error) {
					// console.log('BYPROGRAMMER', response);
				} else {
					for (let item of this.addresses) {
						if (item.id_address === idAddress) {
							item.main_address = 1;
						} else {
							item.main_address = 0;
						}
					}
				}
			},
			error => {
				// console.log('BYPROGRAMMER', error);
			}
		);
	}

	public getZipcodesData() {
		this._addressService.getZipcodeData(this.zipcodeData.zipcode, this.userToken).subscribe(
			response => {
				if (response.error) {
					// console.log('BYPROGRAMMER', response);
				} else {
					this.zipcodes = response.zipcodes;
					console.log(response.zipcodes);
					if (this.zipcodes.length >= 1) {
						this.zipcodeData.id_postal_code = this.zipcodes[0].id_postal_code;
						this.zipcodeData.entity_name = this.zipcodes[0].entity_name;
					}
				}
			},
			error => {
				// console.log('BYPROGRAMMER', error);
			}
		);
	}

	public modalTownShipChanged(event) {
		for (let item of this.zipcodes) {
			if (item.id_postal_code== event) {
				this.zipcodeData.entity_name = item.entity_name;
				// console.log('BYPROGRAMMER', 'OK!');
				break;
			}
		}
	}

}
