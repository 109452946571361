import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import Swal from 'sweetalert2';

import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider, SocialUser } from "angularx-social-login";
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  public page_title: string;
  public user: User;
  public user_reg: User;
  public userTypes: any[] = [];
  public status: string;
  public token: string;
  public identity;
  public details;
  public data_error: string;
  public error_reg: string;
  public success_reg: string;
  public checkbox;
  //faUser = faUser;
  //faKey = faKey;
  _user: SocialUser;

  loggedIn: boolean;

  constructor(
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute,
    private authService: SocialAuthService
  ) {
    this.page_title = 'Identificate';
    this.user = new User(1, '', '', '', '', '', '', '');
    this.user_reg = new User(1, '', '', '', '', '', '', '');
    this.checkbox = false;
  }

  ngOnInit() {
    this.logout();
  }



  login(form) {
    this._userService.login(this.user).subscribe(
      response => {
        console.log('login:', response);
        if (response.status != 'error') {
          this.status = 'success';
          this.token = response;
          this._userService.login(this.user, true).subscribe(
            response => {
              console.log('LoginResponse:', response);
              this.identity = response;
              this.details = this.identity.details;
              localStorage.setItem('token', this.token);
              localStorage.setItem('identity', JSON.stringify(this.identity));
              localStorage.setItem('details', JSON.stringify(this.details));
              Swal.fire('Login exitoso...', 'Ingreso realizado con éxito', 'success');
              this._router.navigate(['/']);
            },
            error => {
              this.status = 'error';
              console.log(<any>error);
            }
          );
        } else {
          Swal.fire('UPS', response.message, 'error');
        }

        form.reset();
      },
      error => {
        this.status = 'error';
        console.log(<any>error);
      }
    );
  }

  logout() {
    this._route.params.subscribe(params => {
      let logout = +params['sure'];

      if (logout == 1) {
        this.signOut();
        localStorage.removeItem('identity');
        localStorage.removeItem('token');
        localStorage.removeItem('details');
        this.identity = null;
        this.token = null;
        this.details = null;
        this._router.navigate(['/']);
      }
    });
  }

  signInWithGoogle(): void {

    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(data => {
      this.authService.authState.subscribe(usera => {
        this._user = usera;
        console.log('signInWithGoogle:', this._user);
        this.login_social(this._user);
        this.loggedIn = (usera != null);
      });
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(data => {
      this.authService.authState.subscribe(usera => {
        this._user = usera;

        this.login_social(this._user);
        this.loggedIn = (usera != null);
      });
    });
  }

  signOut(): void {
    this.authService.signOut();
  }

  view_password() {
    let type = $('#password').attr('type');
    if (type == 'text') {
      $('#password').attr('type', 'password');
    } else if (type == 'password') {
      $('#password').attr('type', 'text');
    }
  }

  close_alert() {
    this.data_error = '';
    this.error_reg = '';
    this.success_reg = '';
  }

  registro(form) {

    this._userService.register(this.user_reg).subscribe(
      response => {
        console.log(response);

        if (response.status == 'success') {
          Swal.fire('Usuario registrado correctamente', response.message, 'success');
          form.reset();
          this._router.navigate(['/iniciar_sesion']);
        }
      },
      errorResponse => {
        console.log(<any>errorResponse);
        console.log(errorResponse.error.error);
        let errores = JSON.stringify(errorResponse.error.error);
        Swal.fire('Algo fue mal...', errores, 'error');
      }
    );

  }

  registro_social(_user) {
    this.user_reg = new User(1, _user.firstName, _user.lastName, '', _user.email, '', _user.id, _user.id);
    console.log(this.user_reg);
    this._userService.register(this.user_reg).subscribe(
      response => {
        if (response.status == 'success') {
          this.login_social_aux(_user);
        }
      },
      errorResponse => {
        console.log(<any>errorResponse);
        console.log(errorResponse.error.error); 
        let errores = JSON.stringify(errorResponse.error.error);
        this.user = new User(1, '', '', '', '', '', '', '');
        Swal.fire('Error', errores, 'error');
      }
    );
  }

  login_social(_user) {
    this.user = new User(1, _user.firstName, _user.lastName, '', _user.email, '', _user.id, _user.id);
    console.log(this.user); 
    this._userService.login(this.user).subscribe(
      response => {

        if (response.status != 'error') {
          this.status = 'success';
          this.token = response;
          this._userService.login(this.user, true).subscribe(
            response => {
              this.identity = response;
              this.details = this.identity.details;
              localStorage.setItem('token', this.token);
              localStorage.setItem('identity', JSON.stringify(this.identity));
              localStorage.setItem('details', JSON.stringify(this.details));
              Swal.fire('Login exitoso...', 'Ingreso realizado con éxito', 'success');
              this._router.navigate(['/']);

            },
            error => {

              this.status = 'error';


            }
          );
        }
      },
      error => {
        /*    this.status = 'error';
           console.log(<any>error); */
        this.registro_social(_user);
      }
    );
  }

  login_social_aux(_user) {
    this.user = new User(1, _user.firstName, _user.lastName, '', _user.email, '', _user.id, _user.id);
    /*   console.log(this.user); */
    this._userService.login(this.user).subscribe(
      response => {
        if (response.status != 'error') {
          this.status = 'success';
          this.token = response;
          this._userService.login(this.user, true).subscribe(
            response => {
              this.identity = response;
              this.details = this.identity.details;
              localStorage.setItem('token', this.token);
              localStorage.setItem('identity', JSON.stringify(this.identity));
              localStorage.setItem('details', JSON.stringify(this.details));
              Swal.fire('Login exitoso...', 'Ingreso realizado con éxito', 'success');
              this._router.navigate(['/']);
            },
            error => {
              /*      this.status = 'error';
                   console.log(<any>error); */
            }
          );
        } else {
          /*     Swal.fire('UPS', response.message, 'error'); */
        }
      },
      error => {
        /*   this.status = 'error';
          console.log(<any>error); */
      }
    );
  }
}
