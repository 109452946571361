import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './components/error/error.component';
import { PublicationComponent } from './components/publication/publication.component';
import { ClientProfileComponent } from './components/client-profile/client-profile.component';
import { MyAccountComponent } from './components/client-profile/my-account/my-account.component';
import { CarritoComponent } from "./components/client-profile/carrito/carrito.component";
import { DeseosComponent } from "./components/client-profile/deseos/deseos.component";
import { AuthenticationGuard } from "./guards/authentication.guard";
import { NoAuthGuardGuard } from "./guards/no-auth-guard.guard";
import { ComprasComponent } from './components/client-profile/compras/compras.component';
import { AddressesComponent } from './components/client-profile/addresses/addresses.component';
import { BuyShoppingCartComponent } from './components/client-profile/buy-shopping-cart/buy-shopping-cart.component';
import {SearchComponent } from '../app/components/search/search.component';

const appRoutes: Routes = [
	{ path: '', component: HomeComponent },
	{ path: 'inicio', component: HomeComponent, canActivate: [NoAuthGuardGuard] },
	{ path: 'login', component: LoginComponent, canActivate: [NoAuthGuardGuard] },
	{ path: 'registro', component: RegisterComponent },
	{path: 'search',component:SearchComponent}	,
	{ path: 'publicacion/:id_publication', component: PublicationComponent },
	{
		path: 'perfil_cliente', component: ClientProfileComponent, children: [
			{ path: '', component: MyAccountComponent },
			{ path: 'carrito', component: CarritoComponent },
			{ path: 'carrito/comprar_carrito/:key_custommer_address', component: BuyShoppingCartComponent },
			{ path: 'deseos', component: DeseosComponent },
			{ path: 'compras', component: ComprasComponent },
			{ path: 'direcciones', component: AddressesComponent },
		], canActivate: [AuthenticationGuard]
	},
	{ path: '**', component: ErrorComponent },
];

export const appRoutingProviders: any[] = [AuthenticationGuard, NoAuthGuardGuard];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes);
