export class User {

        constructor(
                public id: number,
                public name: string,
                public last_name: string,
                public description: string,
                public email: string,                                
                public image: string,
                public password: string,
                public password_repeat: string                
        ) { }

}

export interface Direccion {
        id: number,
        cp: string,
        estado: string,
        ciudad: string,
        colonia: string,
        calle: string,
        telefono: string,
        exterior: string,
        interior: string,
        calle_1: string,
        calle_2: string,
        informacion: string,
        principal: number

}

export interface Colonia {
        id: number,
        nombre: string,

}