import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Address } from '../models/address';
import { global } from './global';

@Injectable({
	providedIn: 'root'
})
export class AddressService {
	private url: string = '';

	constructor(public _httpClient: HttpClient) {
		this.url = global.url;
	}

	// NOTE:
	// Remember implement token in the following method.
	// GET JSON request.
	public getAddresses(keyUser, token): Observable<any> {
		let headers = new HttpHeaders().set('Authorization', token);
		let jsonRequest = { "id_user": keyUser };
		let params = new HttpParams({ fromObject: jsonRequest });
		return this._httpClient.get(this.url + 'user/addresses', { params: params, headers: headers });
	}

	// NOTE:
	// Remember implement token in the following method.
	// POST JSON request.
	public newAddress(address: Address, token: string): Observable<any> {
		let headers = new HttpHeaders().set('Authorization', token);
		return this._httpClient.post(this.url + 'user/addresses/create', address, { headers: headers });
	}

	// NOTE:
	// Remember implement token in the following method.
	// PUT JSON request.
	public updateAddress(address: Address): Observable<any> {
		let headers = new HttpHeaders();
		headers.set('Content-Type', 'application/json');
		return this._httpClient.put(this.url + 'update-user-address', address, { headers: headers });
	}

	// NOTE:
	// Remember implement token in the following method.
	// DELETE JSON request.
	public deleteAddress(keyUser, idAddress, token): Observable<any> {
		let headers = new HttpHeaders().set('Authorization', token);
		let jsonRequest = { "key_user": keyUser, "id_address": idAddress };
		return this._httpClient.put(this.url + 'user/addresses/delete', jsonRequest, { headers: headers });
	}

	public getZipcodeData(zipcode, token): Observable<any> {
		let headers = new HttpHeaders().set('Authorization', token);
		let jsonRequest = { "zipcode": zipcode };
		let params = new HttpParams({ fromObject: jsonRequest });
		return this._httpClient.get(this.url + 'postal_codes?', { params: params, headers: headers });
	}

	// NOTE:
	// Remember implement token in the following method.
	// DELETE JSON request.
	public setMainAddress(idAddress, keyUser, token): Observable<any> {
		let headers = new HttpHeaders().set('Authorization', token);
		let jsonRequest = { "id_address": idAddress, "key_user": keyUser };
		return this._httpClient.put(this.url + 'user/addresses/set-main-address', jsonRequest, { headers: headers });
	}
}
