import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicationService } from '../../services/publication.service';
import { global } from '../../services/global';
import { Publication } from '../../models/publication';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { ShoppingCartService } from "../../services/shopping-cart.service";
import Swal from 'sweetalert2';
@Component({
	selector: 'app-publication',
	templateUrl: './publication.component.html',
	styleUrls: ['./publication.component.css']
})
export class PublicationComponent implements OnInit {
	publication: Publication = new Publication();
	urlStorage: string = global.urlStorage;
	public publications = [];
	// public userId: any = 0;	estaba antes
	public userId: number;

	public publicationInFavorites = false;

	private userToken: any;
	
	constructor(
		private _route: ActivatedRoute,
		private _publicationService: PublicationService,		
		private _shoppingCartService: ShoppingCartService,
		private _userService: UserService,
		private _router: Router,) {

		

		this.urlStorage = global.urlStorage;
		let userIdentity = this._userService.getIdentity();
		if (userIdentity !== null) {
			this.userId = userIdentity.sub;
			this.userToken = this._userService.getToken();
		} else {
			this.userId = 0;
		}
	}




	ngOnInit(): void {
		this._route.params.subscribe(
			params => {
				this.loadPublication(params.id_publication);
			}
		);
	}

	checkFavoritesList(): void {
		this._publicationService.getUserFavoritesList(this.userId.toString(), this._userService.getToken()).subscribe(
			(response: any) => {
				for (let id of response.favorites_list) {
					if (id == this.publication.id_publication) {
						this.publicationInFavorites = true;
					}
				}
			},
			(error: any) => {
				// ...
			}
		);
	}

	loadPublication(idPublication: string) {
		this._publicationService.getPublicationById(idPublication).subscribe(
			response => {
				this.publication = response.publication;
				if (this.userId != 0) {
					this.checkFavoritesList();
				}
			},
			error => {
				//...
			}
		);
	}

    

	public addPublicationToShoppingCart(idPublication: number) {
		if (this.userId === 0) {
			Swal.fire({
				icon: 'info',
				title: '¡Inicia sesión para agregar este producto a tu carrito!',
			});
		} else {
			console.log('this.userId',this.userId)
			this._shoppingCartService.addPublicationToShoppingCart(this.userId, idPublication.toString(), 1, this._userService.getToken()).subscribe(
				response => {
					console.log('response.error',response.error)
					if (response.error) {
						Swal.fire(
							'¡Ups! Algo salio mal',
							'El producto no pudo ser agregado al carrito',
							'error'
						);
					} else {
						Swal.fire({
							icon: 'success',
							title: '¡Producto agregado al carrito!',
							showCancelButton: true,
							confirmButtonText: 'Ver carrito',
							cancelButtonText: 'Seguir buscando productos',
						}).then(result => {
							if (result.isConfirmed) {
								this._router.navigate(['/perfil_cliente/carrito']);
							}
						});
					}
				},
				error => {
					//console.log(JSON.stringify(error))
					Swal.fire(
						'¡Ups! Algo salio mal',
						'El producto no pudo ser agregado al carrito',
						'error'
					);
				}
			);
		}
	}

	public addToShoppingCartAndGoToBuyIt(keyPublication: number): void {
		if (this.userId === 0) {
			Swal.fire({
				icon: 'info',
				title: '¡Inicia sesión para agregar este producto a tu carrito!',
			});
		} else {
			this._shoppingCartService.addPublicationToShoppingCart(this.userId, keyPublication, 1, this.userToken).subscribe(
				(response: any) => {
					this._router.navigate(['/perfil_cliente/carrito']);
				},
				(error: any) => {
					Swal.fire(
						'¡Ups! Algo salio mal',
						'El producto no pudo ser agregado al carrito',
						'error'
					);
				}
			);
		}
	}

	updateFavoritesList(idPublication: number): void {
		if (this.userId != 0) {
			this._publicationService.updateUserFavoritesList(this.userId, idPublication, this._userService.getToken()).subscribe(
				(response: any) => {
					if (this.publicationInFavorites) {
						this.publicationInFavorites = false;
					} else {
						this.publicationInFavorites = true;
					}

				},
				(error: any) => {
					// ...
				}
			);
		} else {
			Swal.fire({
				icon: 'info',
				title: '¡Inicia sesión para agregar este producto a tu lista de favoritos!',
			});
		}

	}
}
