import { Injectable } from '@angular/core';
import { global } from '../services/global';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ShoppingCartService {
    private url: string;
    constructor(private _httpClient: HttpClient) {
        this.url = global.url;

    }


    public addPublicationToShoppingCart(keyUser, keyPublication, quantity, token): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        let jsonData = { "id_user": keyUser, "id_publication": keyPublication, "quantity": quantity };
              
        return this._httpClient.put(this.url + 'user/shopping-cart/add-publication', jsonData, { headers: headers });        
    }

    public getShoppingCartPublications(userId, token): Observable<any> {

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.set('Authorization', token);
        let jsonData = { "id_user": userId };
        let params = new HttpParams({ fromObject: jsonData });

        return this._httpClient.get(this.url + 'user/shopping-cart?id_user=' + userId, { headers: headers });
    }

    public removePublicationFromShoppingCart(userId, keyPublication, token): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);
        let jsonData = { "id_user": userId, "id_publication": keyPublication };
        return this._httpClient.put(this.url + 'user/shopping-cart/remove-publication', jsonData, { headers: headers });
    }

    public buyShoppingCart(data, token): Observable<any> {
        let headers = new HttpHeaders().set('Authorization', token);

        let jsonData = {
            key_customer: data.key_customer,
            key_customer_address: data.key_customer_address,
            token_id: data.token_id,
            device_session_id: data.device_session_id
        };

        return this._httpClient.post(this.url + 'user/shopping-cart/buy', jsonData, { headers: headers });
    }
}
