import { Component, OnInit } from '@angular/core';
import { PublicationService } from '../../services/publication.service';
import { Publication } from '../../models/publication';
import { Search } from '../../models/search';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { global } from '../../services/global';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
	public publications: Publication[];
	public userId: number;
	public urlStorage: string;
	public page = 1;
	public pageSize = 1;
	public totalItems = 12;

  constructor(
		private _route: ActivatedRoute,
		public _publicationService: PublicationService,
		private _router: Router
	) {
		this.urlStorage = global.urlStorage;
		this.publications = [];
	}


  ngOnInit(): void {
    this._route.queryParams.subscribe(params => {
			this.searchPublication(params['title'],1);
		});
  }

  public pageChange(page): void {
	this._route.queryParams.subscribe(params => {
		this.searchPublication(params['title'], page);
	});
}

  private searchPublication(title:any,page: number){
    this._publicationService.findPublicationsByTitle(title,page.toString()).subscribe(
      (response: any) => {
				for (let publication of response.publications.data) {
					if (publication.description.length > 100) {
						publication.description = publication.description.substring(0, 100) + '...';
					}
				}

				this.publications = response.publications.data;
				this.pageSize = response.publications.per_page;
				this.totalItems = response.publications.total;
			},
			(error: any) => {
				// ...
			}
    );

  }

}
