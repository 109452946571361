<br>

<div class="content">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <img src="assets/images/userlogin.svg" alt="Image" class="img-fluid">
      </div>
      <div class="col-md-6 contents">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="mb-4">
              <h3>Entra a tu cuenta</h3>
              <p class="mb-4">Bienvenido a Persishops.</p>
            </div>

            <form class="form-signin" method="POST" #loginForm="ngForm" (ngSubmit)="login(loginForm)">
              <button (click)="signInWithFB()" type="button" class="btn btn-facebook btn-block mb-2"> <i
                  class="fa fa-facebook-f"></i>
                Accesa con Facebook</button>
              <button (click)="signInWithGoogle()" type="button" class="btn btn-google btn-block mb-4"> <i
                  class="fa fa-google"></i>
                Accesa con Google</button>

              <div class="form-group first">
                <label for="username">Usuario</label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="Ingresa correo electronico" #email="ngModel" [(ngModel)]="user.email" required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [ngModelOptions]="{standalone: true}"
                  autocomplete="off">
                <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">
                  El correo no es válido
                </small>
              </div>

              <div class="form-group last mb-4">
                <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Contraseña"
                  #password="ngModel" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.password" required>
                <small *ngIf="!password.valid && password.touched" class="invalid-feedback d-block">
                  La contraseña no es válida
                </small>

              </div>

              <div class="d-flex mb-5 align-items-center">

                <span class="ml-auto"><a href="#" class="forgot-pass">Olvidaste tu contraseña</a></span>
              </div>

              <input type="submit" value="Entrar" class="btn btn-block btn-primary">
            </form>

          </div>
        </div>

      </div>

    </div>
  </div>
</div>