import { Component, OnInit } from '@angular/core';
import { PublicationService } from '../../../services/publication.service';
import { global } from '../../../services/global';

@Component({
	selector: 'app-addons',
	templateUrl: './addons.component.html',
	styleUrls: ['./addons.component.css']
})
export class AddonsComponent implements OnInit {
	publications: any[] = [];
	urlStorage: string = global.urlStorage;
	public page = 1;
	public pageSize = 1;
	public totalItems = 12;
	constructor(private _publicationService: PublicationService) {
		//...
	}

	ngOnInit(): void {
		this.getAllPublications(1);
	}

	pageChange(page): void{
		this.getAllPublications(page);
	}

	getAllPublications(page :number) {
		this._publicationService.getAllPublications(page.toString()).subscribe(
			response => {

				this.publications = response.publications.data;
				this.pageSize = response.publications.per_page;
				this.totalItems = response.publications.total;
			}
		);
	}
}
