<br>
<div class="content">
  <div class="container">
    <div class="row">
      <div class="col-md-6 order-md-2">
        <img src="assets/images/register.svg" alt="Image" class="img-fluid">
      </div>
      <div class="col-md-6 contents">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="mb-4">
              <h3>Registrate en <strong>Persishops</strong></h3>
              <p class="mb-4">Es totalmente <strong>Gratis!.</strong></p>
            </div>
            <form method="POST" #registerForm="ngForm" (ngSubmit)="onSubmit(registerForm)">

              <div class="form-group first">
                <input type="text" #name="ngModel" [(ngModel)]="user.name" [ngModelOptions]="{standalone: true}"
                  required pattern="[a-zA-ZÀ-ÖØ-öø-ÿ ]{2,254}" class="form-control" placeholder="Nombre" required
                  autocomplete="off">
              </div>
              <small *ngIf="!name.valid && name.touched" class="invalid-feedback d-block">
                Capture un nombre válido.
              </small>

              <div class="form-group last">
                <input type="text" class="form-control" #surname="ngModel" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="user.last_name" pattern="[a-zA-ZÀ-ÖØ-öø-ÿ ]{2,254}" placeholder="Apellidos" required>
              </div>
              <small *ngIf="!surname.valid && surname.touched" class="invalid-feedback d-block">
                Los apellidos no son válidos.
              </small>

              <div class="form-group last">
                <input type="email" class="form-control" #email="ngModel" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="user.email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Correo" autocomplete="off">
              </div>
              <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">
                El correo no es válido
              </small>


              <div class="form-group last">
                <input type="password" #password="ngModel" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="user.password" required class="form-control" id="pass"
                  pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})" placeholder="****">
              </div>
              <small *ngIf="!password.valid && password.touched" class="invalid-feedback d-block">
                La contraseña no es válida
                <!--  - al menos un carácter en minúsculas
                - al menos una letra mayúscula
                - al menos un número -->
              </small>

              <div class="form-group last">
                <input type="password" #password_confirmation="ngModel" [ngModelOptions]="{standalone: true}"
                  [ngModel]="user.password_repeat" required pattern="{{ registerForm.value.password }}"
                  class="form-control" id="passrepeat" placeholder="****">
                <small *ngIf="password_confirmation.invalid && (registerForm.submitted)"
                  class="invalid-feedback d-block">
                  La contraseña no coincide
                </small>

              </div>

              <div class="form-check">
                <input class="form-check-input" type="checkbox" #checkbox_terminos="ngModel" [ngModel]="checkbox"
                  name="checkbox_terminos" type="checkbox" required id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  Acepto los terminos y condiciones.
                </label>
              </div>
              <div class="form-group last">
                <input type="submit" value="Guardar" [disabled]="!password.valid && password.touched"
                  class="btn text-white btn-block btn-primary">
              </div>


            </form>
          </div>

        </div>

      </div>
    </div>
  </div>