<div class="container-fluid mt-3">
    <div class="row">
        <div class="col-md-2">
        </div>
        <div class="col-md-8 col-sm-12">
                    <div class="card mb-4 custom-shadows-p0">
                        <div class="card-body">
                        <h4 class="card-title mb-4">Pagos</h4>
                         
                                <ngx-spinner bdColor = "rgba(0,0,0,0.8)" size = "medium" color = "#fff" type = "ball-spin"
                                 [fullScreen] = "true"><p style="color: white" > Procesando Pago... </p></ngx-spinner>

                                <div class="form-group">
                                    <label for="username">Nombre del titular</label>
                                    <input class="form-control" [(ngModel)]="cardData.holderName" [ngModelOptions]="{standalone: true}" type="text" placeholder="Como aparece en la tarjeta" autocomplete="off" data-openpay-card="holder_name">
                                </div> <!-- form-group.// -->

                                <div class="form-group">
                                    <label for="cardNumber">Número de Tarjeta</label>
                                    <div class="input-group">
                                    <input class="form-control" [(ngModel)]="cardData.number" [ngModelOptions]="{standalone: true}" type="text" autocomplete="off" data-openpay-card="card_number">
                                        <div class="input-group-append">
                                            <span class="input-group-text">
                                                <i class="fa fa-cc-visa"></i> &nbsp;  
                                                <i class="fa fa-cc-mastercard"></i> 
                                            </span>
                                        </div>
                                    </div> <!-- input-group.// -->
                                </div> <!-- form-group.// -->

                                <div class="row">
                                
                                <div class="col-md flex-grow-0">
                                <div class="form-group">
                                    <label><span class="hidden-xs">Expiracíon</span> </label>
                                    <div class="form-inline" style="min-width: 220px">
                                        <!-- <input [(ngModel)]="cardData.expirationMonth" class="form-control" type="text" placeholder="Mes" data-openpay-card="expiration_month">-->

                                        <select class="form-control" [(ngModel)]="cardData.expirationMonth" data-openpay-card="expiration_month" style="width:100px">
                                          <option>MM</option>
                                          <option>01</option>
                                          <option>02</option>
                                          <option>03</option>
                                          <option>04</option>
                                          <option>05</option>
                                          <option>06</option>
                                          <option>07</option>
                                          <option>08</option>
                                          <option>09</option>
                                          <option>10</option>
                                          <option>11</option>
                                          <option>12</option>
                                        </select>

                                        <span style="width:20px; text-align: center"> / </span>
                                        
                                        <!--<input [(ngModel)]="cardData.expirationYear" class="form-control" type="text" placeholder="Año" data-openpay-card="expiration_year">-->

                                        <select class="form-control" [(ngModel)]="cardData.expirationYear" data-openpay-card="expiration_year" style="width:100px">
                                          <option>YY</option>                              
                                          <option>25</option>
                                          <option>24</option>
                                          <option>23</option>
                                          <option>22</option>
                                          <option>21</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label data-toggle="tooltip" title="" data-original-title="3 digits code on back side of the card">CVV <i class="fa fa-question-circle"></i></label>
                                    <input class="form-control" [(ngModel)]="cardData.cvv2" type="text" placeholder="3 dígitos" autocomplete="off" data-openpay-card="cvv2">
                                </div> <!-- form-group.// -->
                            </div>
                        </div> <!-- row.// -->

                    <p class="alert alert-success"> <i class="shield"></i> Tus pagos se realizan de forma segura con encriptación avanzada de 256 bits.</p>
                    <button class="subscribe btn btn-primary text-white btn-block" (click)="confirmNewPurchase()" type="button"> Realizar Pago  </button>
                    <div class="openpay float-none mt-3">
                        <div class="logo">Transacciones realizadas vía:</div>                        
                    </div>
                    
                 <!-- form-data open.// -->
                    
                   
                  </div> <!-- card-body.// -->
                </div> <!-- card .// -->
        </div>        
         
      
       
    </div>
</div>
