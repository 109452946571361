<div class="container mt-3">
	<div class="row mt-5 mb-5">
		<div class="col-12 text-center">
			<h4 class="mt-3">Resultados</h4>
		</div>
	</div>
	
	<div class="row">

		<!-- publications start -->
		<div *ngFor="let publication of publications" class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
			<div class="card card-product-grid product-grid6" style="height: 100%;">
				<a [routerLink]="['/publicacion/' + publication.id_publication]" > <img src="{{ urlStorage + publication.product.key_user + '/products/' + publication.product.images }}"> </a>				
				<figcaption class="info-wrap p-3">
					<div class="row">
	                    <div class="col-12 rating-wrap">
	                        <i class="fa fa-star custom-star-color"></i>
	                        <i class="fa fa-star custom-star-color"></i>
	                        <i class="fa fa-star custom-star-color"></i>
	                        <i class="fa fa-star custom-star-color"></i>
	                        <i class="fa fa-star" style="color: lightgray;"></i>
	                        <i class="fa fa-star" style="color: white;"></i>
	                        <i class="fa"> 9/10</i>
	                    </div>
                	</div>
					<div>
						<p><a [routerLink]="['/publicacion/' + publication.id_publication]" class="title">{{ publication.title }}</a></p>
					</div>										
			
					<div style="position: float; bottom: 32px; right: 20px; margin-top: 8px; margin-bottom: 8px;" class="price h5">$ {{ publication.product_price }} <small class="text-muted"> MXN</small></div>					 

					<div style="position: float; bottom: 5px; right: 6px;" [routerLink]="['/publicacion/' + publication.id_publication]">
						<button type="button" class="btn btn-outline-dark btn-sm">Detalles</button>
					</div>
				</figcaption>
				
			</div>
		</div>
		<!-- publications end -->
        
	</div>
    <div class="row">
		<div class="col-12">
			<ngb-pagination (pageChange)="pageChange($event)" [collectionSize]="totalItems" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [boundaryLinks]="true" class="d-flex justify-content-center"></ngb-pagination>
		</div>
	</div>
</div>
