import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
@Injectable({
  providedIn: 'root'
})
export class NoAuthGuardGuard implements CanActivate {

  constructor(
    public _userService: UserService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    /*   console.log("noguardian active"); */
    // if user is authenticated just return true;
    if (this._userService.getIdentity()) {

      this.router.navigate(['/perfil_cliente']);
      return false;


    }

    // if user is not authenticated redirect the user and return false

    return true;
  }

}
