import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { global } from './global';

@Injectable({
	providedIn: 'root'
})
export class PublicationService {
	constructor(private _httpClient: HttpClient) {
		//...
	}

	getAllPublications(page:string): Observable<any> {
		return this._httpClient.get(global.url + 'get-all-publications?page='+page);
	}

	getPublicationById(idPublication: string): Observable<any> {
		return this._httpClient.get(global.url + 'get-publication-by-id?id_publication=' + idPublication);
	}



	public getPublicationsByIds(ids: number[]): Observable<any> {
		return this._httpClient.get(global.url + 'publications/find-by-ids?ids=' + JSON.stringify(ids));
	}

	findPublicationsByTitle(title: string, page: string): Observable<any> {
		let params = 'title=' + title + '&page=' + page;
		return this._httpClient.get(global.url + 'publications/all/by-title?' + params);
	}

	getUserFavoritesList(idUser: string, token: string): Observable<any> {
		let headers = new HttpHeaders().set('Authorization', token);
		return this._httpClient.get(global.url + 'user/favorites-list?id_user=' + idUser, { headers: headers });
	}

	updateUserFavoritesList(idUser: number, idPublication: number, token: string): Observable<any> {
		let headers = new HttpHeaders().set('Authorization', token);
		let jsonData = { id_user: idUser, id_publication: idPublication };
		return this._httpClient.put(global.url + 'user/favorites-list/update', jsonData, { headers: headers });
	}
}
