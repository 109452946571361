<header id="slider-area">  
      <nav class="navbar navbar-expand-md fixed-top scrolling-navbar bg-white">
        <div class="container">          
          <a class="navbar-brand" href="index.html">
            <span>
              <img src="img/logomm.png" alt="">
            </span>            
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <i class="lni-menu"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav mr-auto w-100 justify-content-end">

              <li class="nav-item">
                <a class="nav-link page-scroll" href="#slider-area">Inicio</a>
              </li>

              <li class="nav-item">
                <a class="nav-link page-scroll" href="#comofunciona">¿Como funciona?</a>
              </li>

              <li class="nav-item">
                <a class="nav-link page-scroll" href="#caracteristicas">Categorias</a>
              </li>                                          
              
              <li class="nav-item">
                <a class="nav-link page-scroll" href="#caracteristicas">Contactanos</a>
              </li> 

            </ul>            
          </div>
        </div>
      </nav>



      <!-- Main Carousel Section -->
      <div id="carousel-area">
        <div id="carousel-slider" class="carousel slide carousel-fade" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#carousel-slider" data-slide-to="0" class="active"></li>
            <li data-target="#carousel-slider" data-slide-to="1"></li>            
          </ol>
          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active">
              <img src="img/slider/slide1.png" alt="">
              <div class="carousel-caption text-left">
                <!-- Main Carousel Section 
                <h3 class="wow fadeInRight" data-wow-delay="0.3s">MERCADO MOTOR</h3>
                <h2 class="wow fadeInRight" data-wow-delay="0.6s">REFFACIONES</h2> 
                <h4 class="wow fadeInUp" data-wow-delay="0.9s">AUTOMOVILES</h4>-->
                                
            </div>

            </div>
            <div class="carousel-item">
              <img src="img/slider/slide2.png" alt="">
              <div class="carousel-caption text-left">

                
                
                
              </div>
            </div>
            
          </div>
          <a class="carousel-control-prev" href="#carousel-slider" role="button" data-slide="prev">
            <span class="carousel-control" aria-hidden="true"><i class="lni-chevron-left"></i></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carousel-slider" role="button" data-slide="next">
            <span class="carousel-control" aria-hidden="true"><i class="lni-chevron-right"></i></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>  

    </header>
