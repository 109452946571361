import { Injectable } from '@angular/core';
import { global } from './global';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PurchaseService {



  private url: string;

  constructor(private _httpClient: HttpClient) {
    this.url = global.url;
  }

  // NOTE:
  // Remember implement [token] in the following method
  public getPurchases(keyUser, token): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    let jsonData = { "key_user": keyUser };
    let params = new HttpParams({ fromObject: jsonData });
    return this._httpClient.get(this.url + 'user/purchases', { params: params, headers: headers });
  }

  public getPurchaseById(keyUser, idPurchase, token): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    let jsonData = { "key_user": keyUser, "id_purchase": idPurchase };
    let params = new HttpParams({ fromObject: jsonData });
    return this._httpClient.get(this.url + 'get-purchase-delivery-trackings-by-id', { params: params, headers: headers });
  }
}
