<!-- Show all publications (new) -->
<div *ngFor="let publication of publications; index as i" class="card mt-3 custom-border p-0"
    [class.mb-3]="i == (publications.length - 1)">
    <div class="card-body row pr-4 pl-4">

        <!-- Left content (image) -->
        <div class="col-12 col-md-3 text-center">
            <a [routerLink]="['/publicacion/' + publication.id_publication]" class="img-wrap">
                <!-- Test comment... -->
                <img src="{{ this.urlStorage + publication.product.key_user + '/products/' + getFirstImg(publication.product.images) }}"
                    class="custom-img">
            </a>
        </div>

        <!-- Center content -->
        <div class="col-12 col-md-5 col-lg-6">
            <div class="row">
                <a [routerLink]="['/publicacion/' + publication.id_publication]" class="col-12 h5 title">{{
                    publication.title }}</a>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <span *ngIf="publication.product.key_condition == 1" class="badge badge-success">
                        <i class="fa fa-check-circle"></i>
                        NUEVO
                    </span>
                    <span *ngIf="publication.product.key_condition == 2" class="badge badge-primary">
                        <i class="fa fa-clone"></i>
                        USADO
                    </span>
                    <span *ngIf="publication.product.key_condition == 3" class="badge badge-danger">
                        <i class="fa fa-archive"></i>
                        SEMINUEVO
                    </span>
                </div>
            </div>
            <div class="row mt-3">
                <p class="col-12 custom-text-format">{{publication.description}}</p>
            </div>
        </div>

        <!-- Right content -->
        <div class="col-12 col-md-4 col-lg-3 card">
            <div class="card-body pb-5">
                <div class="row">
                    <div class="col-12 price-wrap">
                        <span class="h5 price">${{ publication.product_price }}</span>
                        <small class="text-muted"> MXN</small>
                    </div>
                </div>
                <div class="row">
                    <small class="col-12 text-success">Envío Gratis.</small>
                </div>
                <div class="row mt-3">
                    <p class="col-12 text-muted">Patrocinado TM</p>
                </div>
                <div class="row">
                    <div class="col-12 rating-wrap">
                        <i class="fa fa-star custom-star-color"></i>
                        <i class="fa fa-star custom-star-color"></i>
                        <i class="fa fa-star custom-star-color"></i>
                        <i class="fa fa-star custom-star-color"></i>
                        <i class="fa fa-star" style="color: lightgray;"></i>
                        <i class="fa fa-star" style="color: white;"></i>
                        <i class="fa"> 9/10</i>
                    </div>
                </div>
            </div>
            <button (click)="updateFavoritesList(publication.id_publication)" class="btn btn-light custom-btn-delete"
                title="Eliminar publicación de tu lista de favoritos">
                <i class="fa fa-trash text-danger"></i>
            </button>
        </div>
        <!-- 
        <p class="custom-message bg-warning">Publicación no disponible</p> -->
    </div>
</div>