import { Component, OnInit } from '@angular/core';
import { PublicationService } from '../../../services/publication.service';
import { UserService } from '../../../services/user.service';
import { global } from '../../../services/global';
@Component({
  selector: 'app-deseos',
  templateUrl: './deseos.component.html',
  styleUrls: ['./deseos.component.css']
})
export class DeseosComponent implements OnInit {
  private userId = 0;
  public publications = [];
  public urlStorage = global.urlStorage;

  constructor(public publicationService: PublicationService,
    public _userService: UserService,) {

    let userIdentity = this._userService.getIdentity();

    if (userIdentity !== null) {
      this.userId = userIdentity.sub;
    } else {
      this.userId = 0;
    }
  }

  ngOnInit(): void {
    if (this.userId != 0) {
      this.getFavoritesList();
    }
  }
  private getFavoritesList(): void {
    this.publicationService.getUserFavoritesList(this.userId.toString(), this._userService.getToken()).subscribe(
      (response: any) => {
        console.log(response.favorites_list);
        this.getPublicationsByIds(response.favorites_list);
      },
      (error: any) => {
        // ...
      }
    );
  }

  private getPublicationsByIds(ids: number[]): void {
    this.publicationService.getPublicationsByIds(ids).subscribe(
      (response: any) => {
        for (let publication of response.publications) {
          if (publication.description.length > 100) {
            publication.description = publication.description.substring(0, 100) + '...';
          }
        }

        this.publications = response.publications;
      },
      (error: any) => {
        // ...
      }
    );
  }
  // Test comment...
  public getFirstImg(strImgs: string): string {
    return strImgs[0];
  }

  public updateFavoritesList(idPublication: number): void {
    this.publicationService.updateUserFavoritesList(this.userId, idPublication, this._userService.getToken()).subscribe(
      (response: any) => {
        for (let i = 0; i < this.publications.length; i++) {
          if (this.publications[i].id_publication == idPublication) {
            this.publications.splice(i, 1);
            break;
          }
        }
      },
      (error: any) => {
        // ...
      }
    );
  }
}
