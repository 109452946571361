<!--=== navigation ===-->
<header id="nav" class n="header">
    <!-- menu -->
    <div style="position:relative;min-height:100px">
        <nav class="navbar navbar-expand-md navbar-fixed-top navbar-light main-nav" style="background-color: #fff;min-height:15
    0px;position: fixed;width:100%;z-index:2">
            <div class="container">
                <ul class="nav navbar-nav mx-auto mt-0">
                    <li class="nav-item">
                        <a class="navbar-brand abs-center-x" [routerLink]="['/']">
                            <img src="assets/images/logo5.png" class="imglogo">
                        </a>
                    </li>
                    <div class="form-inline mr-auto">
                        <div class="input-group mb-0">

                            <input type="text" [(ngModel)]="search.title" (keyup.enter)='Search(search.title)' class="form-control">
                            <div class="input-group-append">
                                <button class="btn btn-warning" [routerLink]="['/search']"
                                    [queryParams]="{title: search.title}" type="submit"> Buscar </button>
                            </div>
                        </div>
                    </div>

                </ul>

                <div>
                    <ul class="nav">
                        <li class="nav-item dropdown">
                            <div class="col-xs-1 text-center">
                                <!--<img *ngIf="details.foto != null ;else guest_fotonav" src="{{ url + 'user/image/profile/' + details.foto}}" class="img-circle  img-responsive" style="width:45px;height:45px;margin-top:10px;" alt="User Pic"> -->

                                <ng-template #guest_fotonav>
                                    <img src="../../../assets/images/guest.png"
                                        class="rounded-circle mx-md-auto d-block" style="width:45px; height:45px;">
                                </ng-template>

                                <!-- <a class="nav-link mx-md-auto" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="margin-right:0px !important;">
                                <span class="d-md-block" style="font-size: 10px;">Johvany Ochoa</span>
                            </a> -->
                            </div>

                            <!--{{ details.nombre }}
                        <div uk-dropdown="pos: bottom-justify" style="margin-top:0px;"  class="uk-dropdown uk-dropdown-close">
                            <a class="dropdown-item" *ngIf="identity.role_user == 'CLIENTE';else perfil_cadian" 
                            [routerLink]="['/perfil-cliente']">Mi perfil</a>
                            <ng-template #perfil_cadian>
                                <a class="dropdown-item" style="border-bottom:1px solid #e2e2e2" [routerLink]="['/perfil-cadian']">Mi perfil</a>
                            </ng-template>
                            <a class="dropdown-item" style="border-bottom:1px solid #e2e2e2" href="#invitar-modal" uk-toggle>Invitar amigo</a>
                                <div class="uk-nav-divider"></div>
                            <a class="dropdown-item" [routerLink]="['/logout/1']">Cerrar sesión</a>
                        </div> -->
                        </li>

                        <!--
                    <li class="nav-item ml-3">
                        <ul class="nav navbar-nav mr-auto uk-nav uk-dropdown-nav mx-auto mt-3 ml-2">
                            <div class="uk-button" style="position:relative;padding:0px;" (mouseenter) ="readNotif($event)">
                                <fa-icon [icon]="faBell" class="rounded-circle p-2" style="background:#e6e6e6;cursor:pointer;" size="lg"></fa-icon>
                                <div *ngIf="unread > 0">
                                    <span class="uk-badge" style="position: absolute;top: 0;margin-left: 5px;">{{ unread }} </span>
                                </div>
                            </div>
                            
                        </ul>
                    </li>
-->

                        <!--  
                    <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" [infiniteScrollContainer]="'.scroll'" [fromRoot]="true" (scrolled)="onScroll()" uk-dropdown="animation: uk-animation-slide-top-small; duration: 500; pos: bottom-right" class="overflow-auto scroll" style="width:350px !important;max-height:300px;overflow:auto;">
                        <ul class="uk-nav uk-dropdown-nav overflow-auto scroll" style="white-space:normal !important;height:300px;">
                            <div *ngIf="notificaciones.length > 0">
                                <li *ngFor="let notificacion of notificaciones">
                                    <a href="{{ linkNotif(notificacion.tipo, notificacion.id_referencia) }} " style="color:#444">
                                        <div class="col-12" style="position:relative;">
                                            <div class="col-3 float-left" style="padding:0px;">
                                                <img class="img-circle" src="{{ url + 'user/image/profile/' + details.foto}}" style="max-width:50px !important;height:50px;">
                                            </div>
                                            <div class="col-9 notif float-left" style="padding:0px;">
                                                <span class="uk-text-middle ml-2">
                                                    {{ notificacion.texto }}
                                                </span>
                                                <br>
                                                <small><strong style="text-align: right;">{{ notificacion.fecha }}</strong></small>
                                                <hr class="mt-2 mb-2">
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </div>

                            <div *ngIf="notificaciones.length == 0">
                                <li>
                                    <div class="col-12 text-center mx-auto" style="padding:0px;">
                                        <span class="uk-text-middle ml-2">
                                            Aún no tienes notificaciones
                                        </span>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div> -->
                    </ul>
                </div>
            </div>
        </nav>
    </div>

    <div style="position:relative;min-height:72px">
        <nav class="navbar navbar-expand-lg navbar-light py-3 shadow-sm"
            style="position: fixed; width:100%; z-index: 1; background: #3980AC !important;">
            <a href="#" class="navbar-brand  d-block d-lg-none">PersiShops</a>
            <button type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbars"
                aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div id="navbarContent" class="collapse navbar-collapse">
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item">
                        <a [routerLink]="['/']" class="nav-link submenu-link">Inicio</a>
                    </li>
                    <li class="nav-item">
                        <a href="/" class="nav-link submenu-link">Persianas</a>
                    </li>
                    <li class="nav-item">
                        <a href="/" class="nav-link submenu-link">Promociones</a>
                    </li>
                    <li class="nav-item"><a [routerLink]="['/']" class="nav-link submenu-link">Tendencias</a></li>
                    <li class="nav-item"><a [routerLink]="['/']" class="nav-link submenu-link">Membresias</a></li>
                </ul>

                <ul *ngIf="!identity" class="nav navbar-nav navbar-right">
                    <li class="nav-item"><a [routerLink]="['/login']" class="nav-link submenu-link">Iniciar Sesión</a>
                    </li>
                    <li class="nav-item"><a [routerLink]="['/registro']" class="nav-link submenu-link">Registrate</a>
                    </li>
                </ul>

                <ul class="navbar-nav mr-auto">
                    <li class="nav-item dropdown" *ngIf="identity">
                        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"><span
                                class="czi-user-circle"></span>
                            <i class="fa fa-user"
                                style="font-size:20px;color:lightblue;text-shadow:1px 1px 2px #000000;"></i>
                            {{identity.name}}
                        </a>
                        <ul class="dropdown-menu">
                            <li>
                                <a [routerLink]="['/perfil_cliente']" class="dropdown-item"><span
                                        class="czi-home"></span> Mi cuenta</a>
                            </li>
                            <li>
                                <a [routerLink]="['/perfil_cliente/carrito']" class="dropdown-item"><span
                                        class="czi-home"></span>
                                    Carrito de compras</a>
                            </li>
                            <li>
                                <a (click)="logout()" class="dropdown-item"><span class="czi-home"></span> Cerrar
                                    Sesión</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item mr-2">
                        <div *ngIf="identity" class="navbar-tool-text mr-auto">
                            <button class="btn btn-outline-light" (click)="logout()" type="submit"> Cerrar
                                Sesión</button>
                        </div>
                    </li>
                </ul>

            </div>
        </nav>
    </div>

</header>