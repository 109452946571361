import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { global } from '../../../services/global';
import { LoginComponent } from '../../../components/login/login.component';
import { Search } from '../../../models/search';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  providers: [UserService]
})

export class NavbarComponent implements OnInit, DoCheck {
  public identity;
  public token;
  public details;
  public url;
  public notificaciones = [];
  public unread: number = 0;
  public subcategorias = [];
  public servicios = [];
  public servicio_selected: number = 0;
  public subcategorias_selected = [];
  public title = '';
  public search: Search;

  constructor(
    public _userService: UserService,    
    private _router: Router  
  ) {    
    this.search = new Search("");
    this.loadUser();
    this.url = global.url;
  }

  public Search(title) {
    this._router.navigate(['/search'],{queryParams:{title: title}});
  }

  ngOnInit(): void {
    // Implement this method when you need it.
  }

  ngDoCheck() {
    this.loadUser();
  }

  loadUser() {
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.details = this._userService.getDetails();
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('identity');
    localStorage.removeItem('details');
    this.identity = null;
    this.token = null;
    this.details = null;
    this.signOut();
  }

  signOut(): void {    
    this._router.navigate(['/']);
    // location.reload();
  }
}



