<main class="col-md-12 mt-3">
    <div class="card">
        <div class="card-body">
            <button (click)="newAddressButton()" class="btn btn-primary mb-3 border border-secondary" data-toggle="modal" data-target="#nuevaDireccion" data-backdrop="static" data-keyboard="false">
                <i class="fa fa-plus"></i>
                Nueva Dirección
            </button>
            <div class="row">
                <div *ngFor="let item of addresses" class="col-md-6">
                    <div class="col-md-6">
                        <div *ngIf="item.main_address === 1; then thenBlock else elseBlock"></div>
                        <ng-template #thenBlock>
                            <div>
                                <article class="box mb-4">
                                    <h6>{{ item.postal_code_data.municipality_name }}, {{ item.postal_code_data.entity_name }}</h6>
                                    <p>{{ item.street }} {{ item.outside_number }} <br> CP {{ item.postal_code_data.id_postal_code }}</p>
                                    <a href="#" class="btn btn-primary disabled">
                                        <i class="fa fa-check"></i>
                                        Principal
                                    </a>
                                    <button  (click)=" confirmDeleteAddress(item.id_address)" class="btn btn-light">
                                        <i class="text-danger fa fa-trash" ></i>
                                    </button>
                                </article>
                            </div>
                        </ng-template>
                        <ng-template #elseBlock>
                            <div>
                                <article class="box mb-4">
                                    <h6>{{ item.postal_code_data.municipality_name}}, {{ item.postal_code_data.entity_name }}</h6>
                                    <p>{{ item.street }} {{ item.inside_number }} <br> CP {{ item.postal_code_data.id_postal_code }}</p>
                                    <a (click)="setMainAddress(item.id_address)" class="btn btn-light"><i class="fa fa-pencil"></i> Hacer Principal</a>
                                   
                                    <button  (click)=" confirmDeleteAddress(item.id_address)" class="btn btn-light">
                                        <i class="text-danger fa fa-trash" ></i>
                                    </button>
                                </article>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal to create a new address -->
        <div class="modal fade" id="nuevaDireccion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Nueva dirección</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-group row">
                                <div class="col-6">
                                    <label for="message-text" class="col-form-label">Código postal</label>
                                    <input type="text" class="form-control" [(ngModel)]="zipcodeData.zipcode" [ngModelOptions]="{standalone: true}" required>
                                </div>
                                <div class="col-6">
                                    <label for="message-text" class="col-form-label">&nbsp;</label>
                                    <button (click)="getZipcodesData()" class="btn btn-light border form-control">Revisar código postal</button>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-6">
                                    <label for="message-text" class="col-form-label">Estado</label>
                                    <input type="text" class="form-control" [(ngModel)]="zipcodeData.entity_name" [ngModelOptions]="{standalone: true}" readonly>
                                </div>
                                <div class="col-6">
                                    <label for="message-text" class="col-form-label">Colonia</label>
                                    <select [(ngModel)]="zipcodeData.id_zipcode" (ngModelChange)="modalTownShipChanged($event)" class="form-control" [ngModelOptions]="{standalone: true}">
                                        <option value="{{zc.id_zipcode}}" *ngFor="let zc of zipcodes">{{ zc.township_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-6">
                                    <label for="message-text" class="col-form-label">Calle</label>
                                    <input type="text" class="form-control" [(ngModel)]="zipcodeData.street" [ngModelOptions]="{standalone: true}" required>
                                </div>
                                <div class="col-6">
                                    <label for="message-text" class="col-form-label">Teléfono de contacto</label>
                                    <input type="text" class="form-control" [(ngModel)]="zipcodeData.phone_number" [ngModelOptions]="{standalone: true}" required>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-6">
                                    <label for="message-text" class="col-form-label">Numero exterior</label>
                                    <input type="text" class="form-control" [(ngModel)]="zipcodeData.outside_number" [ngModelOptions]="{standalone: true}" required>
                                </div>
                                <div class="col-6">
                                    <label for="message-text" class="col-form-label">Numero interior</label>
                                    <input type="text" class="form-control" [(ngModel)]="zipcodeData.inside_number" [ngModelOptions]="{standalone: true}" required>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-6">
                                    <label for="message-text" class="col-form-label">Entre calle 1</label>
                                    <input type="text" class="form-control" [(ngModel)]="zipcodeData.street_1" [ngModelOptions]="{standalone: true}" required>
                                </div>
                                <div class="col-6">
                                    <label for="message-text" class="col-form-label">Entre calle 2</label>
                                    <input type="text" class="form-control" [(ngModel)]="zipcodeData.street_2" [ngModelOptions]="{standalone: true}" required>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-12">
                                    <label for="message-text" class="col-form-label">Información adicional</label>
                                    <textarea cols="30" rows="3" [(ngModel)]="zipcodeData.information" [ngModelOptions]="{standalone: true}" required class="form-control"></textarea>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                <button class="btn btn-success"  (click)="saveAddress()">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


    </div>
</main>
<!-- col.// -->
