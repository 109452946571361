import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [UserService]
})

export class RegisterComponent implements OnInit {
  public page_title: string;
  public user: User;
  public checkbox;
  public tipos_cliente;


  constructor(
    private _registerClientService: UserService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.page_title = 'Registrate';
    this.user = new User(1, '', '', '', '', '', '', '');
    this.checkbox = false;
  }

  ngOnInit() {
    console.log(this.user);
  }

  onSubmit(form) {


    /* console.log(form); */
    this._registerClientService.register(this.user).subscribe(
      response => {
        if (response.status == 'success') {
          Swal.fire('Usuario registrado correctamente', response.message, 'success');
          form.reset();
          this._router.navigate(['/iniciar_sesion']);
        }
      },
      errorResponse => {
        let errores = JSON.stringify(errorResponse.error.error);
        Swal.fire('Algo fue mal...', errores, 'error');
      }
    );
  }
}
